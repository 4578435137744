import { Dispatch, SetStateAction } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { EditingQuestionItem } from '../../interface/Question';
import QuestionItem from './QuestionItem';

type SortableQuestionItemProps = {
  index: number;
  item: EditingQuestionItem;
  editableIndex: number;
  existEmptyItem: boolean;
  existQuestionsWithAllItemsDeleted: boolean;
  setEditableIndex: Dispatch<SetStateAction<number>>;
};

const SortableQuestionItem: React.FC<SortableQuestionItemProps> = (props) => {
  const {
    index,
    item,
    editableIndex,
    existEmptyItem,
    existQuestionsWithAllItemsDeleted,
    setEditableIndex
  } = props;

  const {
    isDragging,
    setActivatorNodeRef,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: item.id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 10 : undefined
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <div
          ref={setActivatorNodeRef}
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
            display: 'flex',
            alignItems: 'center'
          }}
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon fontSize="small" />
        </div>
        <QuestionItem
          item={item}
          index={index}
          editableIndex={editableIndex}
          setEditableIndex={setEditableIndex}
          existEmptyItem={existEmptyItem}
          existQuestionsWithAllItemsDeleted={existQuestionsWithAllItemsDeleted}
        />
      </Box>
    </div>
  );
};

export default SortableQuestionItem;
