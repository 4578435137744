import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import { QuestionResponse } from '../interface/Question';
import {
  Answer,
  AnswerMaster,
  AnswerMasterForEdit,
  AnswerPostResponse,
  ChunkUpsertAnswerRequest,
  PreviousAnswerQueryParam,
  ValidatingAnswer
} from '../interface/Answer';
import {
  AnswersTableResponse,
  FilterCondition,
  SortCondition
} from '../interface/AnswersTable';
import {
  EditedQuestionnaire,
  Questionnaire,
  QuestionnaireGetResponse,
  QuestionnaireMetaData,
  QuestionnaireByUserIdRequest
} from '../interface/Questionnaire';
import { eventHeaders } from '../common/auth';
import {
  NotificationCreationRequest,
  NotificationDetail,
  NotificationType,
  Notification
} from '../interface/Notification';
import { User } from '../interface/User';
import awsConfig from '../common/aws-exports';

const API_BASE_URL = `https://${awsConfig.apiGatewayId}.execute-api.ap-northeast-1.amazonaws.com/prod`;

export const fetchQuestions = async (
  idOrHash: number | string,
  isAll: boolean
): Promise<QuestionResponse> => {
  const requestParameter =
    typeof idOrHash === 'number'
      ? { questionnaireId: idOrHash, isAll }
      : { hash: idOrHash, isAll };
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(`${API_BASE_URL}/question`, {
    params: requestParameter,
    ...headers
  });

  return response.data;
};

export const fetchPreviousAnswers = async (
  requestParameter: PreviousAnswerQueryParam,
  isSameUser: boolean
): Promise<Answer[] | undefined> => {
  const user = await Auth.currentAuthenticatedUser();
  const userId = user.attributes.email;
  const headers: AxiosRequestConfig = await eventHeaders();

  try {
    const response = isSameUser
      ? await axios.post(
          `${API_BASE_URL}/answer/inheritance/user`,
          {
            userId,
            hash: requestParameter.hash,
            answer:
              requestParameter.questionId !== undefined
                ? {
                    questionId: requestParameter.questionId,
                    itemId: requestParameter.itemId,
                    textAnswer: requestParameter.textAnswer
                  }
                : undefined
          },
          headers
        )
      : await axios.get(`${API_BASE_URL}/answer/inheritance`, {
          ...headers,
          params: requestParameter
        });
    return response.data.answers;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
      return undefined;
    } else if (
      axios.isAxiosError(e) &&
      e.response &&
      e.response.status >= 400
    ) {
      throw new Error(e.message);
    }
  }
};

export const submitNewAnswers = async (
  answerMaster: AnswerMaster
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/answer`,
    answerMaster,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const saveTempAnswer = async (
  questionnaireId: number,
  temporaryAnswers: string,
  keyItemId?: number,
  keyTextAnswer?: string
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.post(
    `${API_BASE_URL}/temporary-answer/${questionnaireId}`,
    {
      temporaryAnswers,
      keyItemId,
      keyTextAnswer
    },
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const updateAnswers = async (
  answerMasterForEdit: AnswerMasterForEdit
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/answer`,
    answerMasterForEdit,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const fetchAnswersByMetadataId = async (
  metadataId: number
): Promise<Answer[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/answer/${metadataId}`, {
    ...headers
  });
  return response.data.answers;
};

export const fetchAnswers = async (
  questionnaireId: number,
  limit: number,
  offset: number
): Promise<AnswersTableResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/answer`, {
    params: { questionnaireId, limit, offset },
    ...headers
  });

  return {
    headers: response.data.headers,
    answers: response.data.answers,
    totalCount: response.data.totalCount
  };
};

export const fetchFilteredAnswers = async (
  questionnaireId: number,
  isUnique: boolean,
  limit: number,
  offset: number,
  filter?: FilterCondition[],
  sort?: SortCondition[]
): Promise<AnswersTableResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.post(
    `${API_BASE_URL}/answer/${
      isUnique ? 'unique' : 'filter'
    }/${questionnaireId}`,
    {
      limit,
      offset,
      filter,
      sort
    },
    headers
  );

  return {
    headers: response.data.headers,
    answers: response.data.answers,
    totalCount: response.data.totalCount
  };
};

export const fetchAnswersByMetadataIds = async (
  metadataIds: number[]
): Promise<ValidatingAnswer[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/answer/chunk`,
    { metadataIds },
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.answers;
};

export const chunkUpsertAnswers = async (
  request: ChunkUpsertAnswerRequest
): Promise<string> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/answer/chunk`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.message;
};

export const deleteAnswers = async (
  metadataIds: number[],
  userId: string,
  questionnaireId: number
): Promise<number[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.delete(`${API_BASE_URL}/answer`, {
    ...headers,
    data: { metadataIds, userId, questionnaireId }
  });

  return response.data.deleted;
};

export const postQuestionnaire = async (questionnaire: Questionnaire) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/question`,
    questionnaire,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};

export const fetchQuestionnaires = async (
  limit: number,
  offset: number,
  isAll?: boolean
): Promise<QuestionnaireGetResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/questionnaire`, {
    params: { limit, offset, isAll },
    ...headers
  });

  return {
    questionnaires: response.data.questionnaires,
    totalCount: response.data.totalCount
  };
};

export const fetchQuestionnaireByHash = async (
  hash: string
): Promise<QuestionnaireMetaData> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/questionnaire/${hash}`, {
    ...headers
  });

  return response.data.questionnaire;
};

export const fetchQuestionnaireByUserId = async (
  userId: string,
  isAll?: boolean
): Promise<QuestionnaireMetaData[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const requestBody: QuestionnaireByUserIdRequest = {
    userId: userId,
    isAll: isAll === undefined ? false : isAll
  };

  const response = await axios.post(
    `${API_BASE_URL}/questionnaire/user/`,
    requestBody,
    headers
  );

  return response.data.questionnaires;
};

export const updateQuestionnaire = async (
  questionnaire: EditedQuestionnaire
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/question`,
    questionnaire,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};

export const deleteQuestionnaire = async (hash: string) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.delete(`${API_BASE_URL}/questionnaire/${hash}`, {
    ...headers
  });

  return response.data.deletedId;
};

export const fetchNotificationTypes = async (): Promise<NotificationType[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/notifications/type`, {
    ...headers
  });

  return response.data.types;
};

export const postNotification = async (
  request: NotificationCreationRequest
): Promise<number> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/notifications`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.id;
};

export const fetchNotifications = async (
  limit: number,
  offset: number,
  isAll?: boolean
): Promise<Notification[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(`${API_BASE_URL}/notifications`, {
    params: { limit, offset, isAll },
    ...headers
  });
  return response.data.notifications;
};

export const fetchNotificationDetail = async (
  notificationId: number
): Promise<NotificationDetail | undefined> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  try {
    const response = await axios.get(
      `${API_BASE_URL}/notifications/${notificationId}`,
      {
        ...headers
      }
    );
    return response.data.notificationDetail;
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      e.response &&
      (e.response.status === 404 || e.response.status === 400)
    ) {
      return undefined;
    } else if (
      axios.isAxiosError(e) &&
      e.response &&
      e.response.status >= 400
    ) {
      throw new Error(e.message);
    }
  }
};

export const updateNotification = async (
  request: NotificationCreationRequest,
  id: number
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/notifications/${String(id)}`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.id;
};

export const updateAuthorities = async (
  questionnaireIds: number[],
  deleteIds: number[],
  userId: string,
  name: string
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/authority/user`,
    { questionnaireIds, deleteIds, name, userId },
    headers
  );
  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
  return response.data.ids;
};

export const updateFormAuthorities = async (
  questionnaireId: number,
  users: User[]
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/authority/questionnaire/${questionnaireId}`,
    {
      users: users.map((user: User) => ({
        userId: user.email,
        name: user.name
      }))
    },
    headers
  );
  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};

export const fetchAuthorizedUsers = async (
  questionnaireId: number
): Promise<User[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(
    `${API_BASE_URL}/authority/questionnaire/${questionnaireId}`,
    {
      ...headers
    }
  );
  return response.data.users.map((user: { userId: string; name: string }) => ({
    id: '',
    name: user.name,
    email: user.userId,
    role: ''
  }));
};

export const fetchTemporarySavedAnswer = async (
  questionnaireId: number,
  itemId?: number,
  textAnswer?: string
): Promise<Answer[]> => {
  try {
    const headers: AxiosRequestConfig = await eventHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/temporary-answer/${questionnaireId}`,
      {
        ...headers,
        params: { itemId, textAnswer }
      }
    );

    if (response.status === 204) {
      return [];
    }
    return JSON.parse(response.data.temporaryAnswers);
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
