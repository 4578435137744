import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { EditingQuestion, QuestionTypeObject } from '../../interface/Question';
import {
  DESCRIBABLE_QUESTION_TYPES,
  SELECTABLE_QUESTION_TYPES,
  questionTypes
} from '../../common/questionType';
import ItemArea from './ItemArea';
import QuestionCardAction from './QuestionCardAction';
import { useDispatch } from 'react-redux';
import {
  changeQuestionType,
  updateEditingQuestion
} from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import EditableAnswerRanges from './EditableAnswerRanges';
import {
  getQuestionIndexesHaveDuplicateHeadline,
  isQuestionWithItem
} from '../../common/manageQuestion';
import InformationTag from './InformationTag';
import { useLocation } from 'react-router-dom';
import { BASE_COLOR } from '../../common/color';

type EditableQuestionCardProps = {
  isTop: boolean;
  isBottom: boolean;
  deletable: boolean;
};

const EditableQuestionCard: React.FC<EditableQuestionCardProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const isBottom: boolean = props.isBottom;
  const deletable: boolean = props.deletable;

  const isDeleted: boolean = 'isDeleted' in question && question.isDeleted;

  const isHeadlineUnenteredError: boolean = question.headline === '';
  const isHeadlineDuplicateError: undefined | string[] =
    getQuestionIndexesHaveDuplicateHeadline(question, validationTarget);

  const isQuestionBodyError: boolean = question.question === '';

  const updateQuestion =
    (key: string, valueType: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const updatedValue: string | boolean =
        valueType === 'string'
          ? (event.target.value as string)
          : event.target.checked;

      return dispatch(updateEditingQuestion({ key, value: updatedValue }));
    };
  const handleSelectChange = (event: SelectChangeEvent) => {
    dispatch(changeQuestionType(event.target.value));
  };

  const createQuestionTypeMenuItem = (
    questionType: QuestionTypeObject,
    disabled: boolean
  ): JSX.Element => {
    return (
      <MenuItem
        key={questionType.type}
        value={questionType.type}
        disabled={disabled}
      >
        {questionType.name}
      </MenuItem>
    );
  };

  // 新規フォーム作成時の質問の種類
  const createQuestionTypesForFormCreate = (): JSX.Element[] => {
    if (SELECTABLE_QUESTION_TYPES.includes(question.type)) {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          DESCRIBABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    } else {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          SELECTABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    }
  };

  // フォーム編集時の質問の種類
  const createQuestionTypesForFormEdit = () => {
    if (question.type === 'select' || question.type === 'radio') {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          questionType.type === 'check' ||
            DESCRIBABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    } else if (question.type === 'text' || question.type === 'longtext') {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          SELECTABLE_QUESTION_TYPES.includes(questionType.type) ||
            questionType.type === 'number'
        )
      );
    } else {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(questionType, true)
      );
    }
  };

  const createQuestionTypes = () => {
    // 新規フォーム作成画面での質問項目変更時の挙動
    if (location.pathname.split('/')[1] === 'form-management') {
      return createQuestionTypesForFormCreate();
      // フォーム編集画面での質問項目変更時の挙動
    } else {
      return createQuestionTypesForFormEdit();
    }
  };

  return (
    <Box id={'editable-question-card'}>
      <div>
        {question.questionConditions !== undefined &&
        question.questionConditions.flat().length !== 0 ? (
          <InformationTag color={BASE_COLOR} content="分岐条件あり" width={8} />
        ) : (
          <></>
        )}
        <FormControl
          variant="standard"
          sx={{ width: '30%' }}
          className="question-type-select"
        >
          <InputLabel>質問の種類</InputLabel>
          <Select
            label="質問の種類"
            value={question.type}
            onChange={handleSelectChange}
            disabled={isDeleted}
          >
            {createQuestionTypes()}
          </Select>
        </FormControl>
      </div>
      <TextField
        label="見出し"
        variant="standard"
        sx={{ marginTop: '0.5em', width: '30%' }}
        value={question.headline}
        onChange={updateQuestion('headline', 'string')}
        disabled={isDeleted}
        aria-label="headline-text-field"
        error={
          isHeadlineUnenteredError || isHeadlineDuplicateError !== undefined
        }
        helperText={
          isHeadlineUnenteredError === true
            ? '見出しを入力してください'
            : isHeadlineDuplicateError !== undefined
            ? `他の質問と見出しが重複しています（${isHeadlineDuplicateError}）`
            : ''
        }
        className="headline-input"
      />
      <TextField
        label="質問文"
        multiline
        variant="standard"
        fullWidth
        value={question.question}
        onChange={updateQuestion('question', 'string')}
        sx={{ marginTop: '0.5em' }}
        disabled={isDeleted}
        error={isQuestionBodyError}
        helperText={
          isQuestionBodyError === true ? '質問文を入力してください' : ''
        }
        aria-multiline
        minRows={3}
        className="question-textarea"
      />
      {SELECTABLE_QUESTION_TYPES.includes(question.type) && (
        <ItemArea items={question.items!} />
      )}
      {DESCRIBABLE_QUESTION_TYPES.includes(question.type) && (
        <EditableAnswerRanges ranges={question.ranges} type={question.type} />
      )}
      <Divider />
      <QuestionCardAction
        index={editingIndex}
        isDeleted={isDeleted}
        isTop={props.isTop}
        isBottom={isBottom}
        required={question.required}
        canInherit={question.canInherit}
        deletable={deletable}
        isEditing={true}
        isQuestionWithItem={isQuestionWithItem(question)}
      />
    </Box>
  );
};

export default EditableQuestionCard;
