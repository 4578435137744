import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../common/ConfirmModal';
import { useSelector } from '../../redux/store';
import { EditingQuestion, QuestionGroup } from '../../interface/Question';
import { Inheritance } from '../../interface/Inheritance';

const CancelButton: React.FC = () => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const initialQuestions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.initialQuestions
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const initialGroups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.initialGroups
  );
  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const initialInheritance: Inheritance = useSelector(
    (state) => state.questionnaire.initialInheritance
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const initialIsPublic: boolean = useSelector(
    (state) => state.questionnaire.initialIsPublic
  );
  const name: string = useSelector((state) => state.questionnaire.name);
  const initialQuestionnaireName: string = useSelector(
    (state) => state.questionnaire.initialQuestionnaireName
  );

  const isQuestionnaireChanged: boolean =
    !_.isEqual(questions, initialQuestions) ||
    !_.isEqual(groups, initialGroups) ||
    !_.isEqual(inheritance, initialInheritance) ||
    !_.isEqual(isPublic, initialIsPublic) ||
    !_.isEqual(name, initialQuestionnaireName);

  return (
    <>
      <ButtonWithToolTip
        title="キャンセル"
        onClick={() => {
          if (isQuestionnaireChanged) setIsOpenModal(true);
          else navigate('/form-management');
        }}
        icon={<CloseIcon fontSize="large" />}
        sx={{ top: 65, right: 10, position: 'absolute' }}
        className="edit-cancel-button"
      />
      <ConfirmModal
        isOpen={isOpenModal}
        question="編集内容を破棄してフォーム一覧画面に戻ってよろしいですか？"
        handleClose={() => {}}
        execute={() => {
          navigate('/form-management');
        }}
        quit={() => {
          setIsOpenModal(false);
        }}
      />
    </>
  );
};

export default CancelButton;
