import { UniqueIdentifier } from '@dnd-kit/core';
import {
  ExistingQuestion,
  Question,
  QuestionItem,
  ExistingQuestionItem,
  FetchedQuestion,
  EditingQuestion,
  EditingQuestionItem,
  EditingAnswerRange,
  AnswerRange,
  QuestionGroup,
  EditingQuestionOrGroup,
  GroupedEditingQuestion,
  QuestionType,
  GroupedQuestion
} from '../interface/Question';
import { SELECTABLE_QUESTION_TYPES, TEXT_QUESTION_TYPES } from './questionType';
import { MAX_TEXT_LENGTH } from './answer';
import { Inheritance } from '../interface/Inheritance';

export const completelyExpandQuestionResponse = (
  questionResponse: FetchedQuestion[]
): ExistingQuestion[] => {
  const flattened: Question[] = questionResponse
    .map((question: FetchedQuestion): Question | Question[] => {
      if ('group' in question) {
        return question.questions.map((innerQuestion: Question) => ({
          ...innerQuestion,
          groupId: question.groupId
        }));
      }

      return question;
    })
    .flat();

  return flattened.map((question: Question): ExistingQuestion => {
    const ranges: EditingAnswerRange[] | undefined =
      question.ranges === undefined
        ? undefined
        : question.ranges.map((range: AnswerRange) => ({
            minValue:
              range.minValue === undefined ? undefined : String(range.minValue),
            maxValue:
              range.maxValue === undefined ? undefined : String(range.maxValue),
            id: range.id,
            includesBoundaryValue: range.includesBoundaryValue,
            isEqual:
              range.minValue !== undefined &&
              range.maxValue !== undefined &&
              range.minValue === range.maxValue
          }));

    return {
      ...question,
      items:
        question.items !== undefined
          ? question.items.map(
              (item: QuestionItem): ExistingQuestionItem => ({
                ...item
              })
            )
          : undefined,
      ranges,
      isOpen: true
    };
  });
};

export const confirmInheritanceExistence = (
  questions: EditingQuestion[],
  editingQuestion: EditingQuestion,
  editingIndex: number
): boolean =>
  questions
    .filter((_, index: number) => index !== editingIndex)
    .some((question: EditingQuestion) => question.canInherit) ||
  (editingIndex !== -1 && editingQuestion.canInherit);

export const isItemDeleted = (item: EditingQuestionItem): boolean =>
  'isDeleted' in item && item.isDeleted;

export const isEmptyItem = (item: EditingQuestionItem): boolean =>
  item.name === '';

export const isQuestionWithItem = (question: EditingQuestion): boolean =>
  SELECTABLE_QUESTION_TYPES.includes(question.type);

export const isMaxNumber = (max: string | undefined): boolean =>
  max === undefined || !(isNaN(Number(max)) || max === '');
export const isMinNumber = (min: string | undefined): boolean =>
  min === undefined || !(isNaN(Number(min)) || min === '');
export const isMaxGreaterThanMinWhenNotEqual = (
  max: string | undefined,
  min: string | undefined,
  isEqual: boolean
): boolean => {
  if (isEqual) return true;

  if (!isMaxNumber(max) || !isMinNumber(min)) return false;

  return max === undefined || min === undefined || Number(min) < Number(max);
};

const calculateInsertionIndex = (
  questions: EditingQuestionOrGroup[],
  groupedEditingQuestion: GroupedEditingQuestion
): number => {
  // 前の質問IDとグループIDがundefinedであれば、先頭に挿入すべきグループであると判断できる
  if (
    groupedEditingQuestion.nextTo.groupId === undefined &&
    groupedEditingQuestion.nextTo.questionId === undefined
  )
    return 0;

  // 質問IDがundefinedではなく、グループIDのみundefinedであれば一つ前は質問であることが分かるので、対象の質問を見つけ、その一つ後のindexが挿入位置となる
  if (groupedEditingQuestion.nextTo.groupId === undefined)
    return (
      questions.findIndex(
        (questionOrGroup: EditingQuestionOrGroup) =>
          'id' in questionOrGroup &&
          questionOrGroup.id === groupedEditingQuestion.nextTo.questionId
      ) + 1
    );

  // グループIDがundefinedではない場合は、一つ前はグループであるため、対象のグループを見つけ、その一つ後のindexが挿入位置となる
  return (
    questions.findIndex(
      (questionOrGroup: EditingQuestionOrGroup) =>
        'groupId' in questionOrGroup &&
        questionOrGroup.groupId === groupedEditingQuestion.nextTo.groupId
    ) + 1
  );
};

const sortGroups = (
  questions: EditingQuestion[],
  groupedQuestions: GroupedEditingQuestion[]
): GroupedEditingQuestion[] => {
  // 先頭に配置するグループ化された質問(アンケート内でGroupが先頭の時)
  const topGroupedQuestions: GroupedEditingQuestion[] = groupedQuestions.filter(
    (groupedQuestion: GroupedEditingQuestion) =>
      groupedQuestion.nextTo.questionId === undefined
  );

  //先頭のグル―プでないグル―プの一つ前の質問IDのみを抽出
  const previousQuestionIds: number[] = groupedQuestions
    .filter(
      (groupedQuestion: GroupedEditingQuestion) =>
        groupedQuestion.nextTo.questionId !== undefined
    )
    .map(
      (groupedQuestion: GroupedEditingQuestion) =>
        groupedQuestion.nextTo.questionId!
    );

  //previousQuestionIdsの順番を、引数で受け取ったアンケートのquestionsの順番に応じた順にする
  const sortedPreviousQuestionIds: number[] = questions
    .filter((question: EditingQuestion) =>
      previousQuestionIds.includes(question.id)
    )
    .map((question: EditingQuestion) => question.id);

  // グループ化された質問を一つ前の質問ID毎に分割
  const dividedByPreviousQuestion: GroupedEditingQuestion[][] = [
    [...topGroupedQuestions],
    ...sortedPreviousQuestionIds.map((previousQuestionId: number) =>
      groupedQuestions.filter(
        (groupedQuestion: GroupedEditingQuestion) =>
          groupedQuestion.nextTo.questionId === previousQuestionId
      )
    )
  ].filter(
    (groupedQuestions: GroupedEditingQuestion[]) => groupedQuestions.length > 0
  );

  // 分割された質問を一つ前のグループIDでソート
  const sortedByPreviousGroupId: GroupedEditingQuestion[][] =
    dividedByPreviousQuestion.map(
      (groupedQuestionsPerPreviousQuestionId: GroupedEditingQuestion[]) => {
        // 先頭のグループを取得
        const topGroupedQuestion: GroupedEditingQuestion =
          groupedQuestionsPerPreviousQuestionId.find(
            (groupedQuestion: GroupedEditingQuestion) =>
              groupedQuestionsPerPreviousQuestionId.find(
                (innerGroupedQuestion: GroupedEditingQuestion) =>
                  innerGroupedQuestion.groupId ===
                  groupedQuestion.nextTo.groupId
              ) === undefined
          )!;

        // 配列の最後尾のグループのIDを「一つ前のグループID」として持つグループを探してそれを配列に入れていくことでソートする
        return [
          ...Array(groupedQuestionsPerPreviousQuestionId.length - 1)
        ].reduce(
          (accumulator: GroupedEditingQuestion[], _) => {
            const nextGroupedQuestion: GroupedEditingQuestion =
              groupedQuestionsPerPreviousQuestionId.find(
                (groupedQuestion: GroupedEditingQuestion) =>
                  groupedQuestion.nextTo.groupId ===
                  accumulator[accumulator.length - 1].groupId
              )!;

            return [...accumulator, nextGroupedQuestion];
          },
          [topGroupedQuestion]
        );
      }
    );

  return sortedByPreviousGroupId.flat();
};

export const groupQuestions = (
  questions: EditingQuestion[],
  groups: QuestionGroup[]
): EditingQuestionOrGroup[] => {
  if (groups.length === 0) return questions;
  const groupedQuestions: GroupedEditingQuestion[] = groups.map(
    (group: QuestionGroup, index: number) => {
      const grouped: EditingQuestion[] = questions.filter(
        (question: EditingQuestion) =>
          'groupId' in question && question.groupId === group.id
      );
      return {
        groupId: group.id,
        groupName: group.name,
        nextTo: group.nextTo,
        questions: grouped,
        isOpen: group.isOpen
      };
    }
  );

  const notGroupedQuestions: EditingQuestion[] = questions.filter(
    (question: EditingQuestion) =>
      !('groupId' in question && question.groupId !== undefined)
  );

  const sortedGroupedQuestions: GroupedEditingQuestion[] = sortGroups(
    questions,
    groupedQuestions
  );

  const groupsAndQuestions: EditingQuestionOrGroup[] = (
    notGroupedQuestions as EditingQuestionOrGroup[]
  ).concat(sortedGroupedQuestions as EditingQuestionOrGroup[]);

  return groupsAndQuestions.reduce(
    (
      accumulator: EditingQuestionOrGroup[],
      groupOrQuestion: EditingQuestionOrGroup
    ) => {
      if ('question' in groupOrQuestion)
        return [...accumulator, groupOrQuestion];

      const insertionIndex: number = calculateInsertionIndex(
        accumulator,
        groupOrQuestion
      );
      return accumulator.toSpliced(insertionIndex, 0, groupOrQuestion);
    },
    []
  );
};

export const getGroupedQuestionsExceptTarget = (
  groupedQuestions: EditingQuestionOrGroup[],
  activeId: number
): EditingQuestionOrGroup[] => {
  return groupedQuestions
    .filter(
      (questionOrGroup: EditingQuestionOrGroup) =>
        'groupName' in questionOrGroup || questionOrGroup.id !== activeId
    )
    .map((questionOrGroup: EditingQuestionOrGroup) => {
      if ('question' in questionOrGroup) return questionOrGroup;
      const exceptedActive: EditingQuestion[] =
        questionOrGroup.questions.filter(
          (question: EditingQuestion) => question.id !== activeId
        );
      return { ...questionOrGroup, questions: exceptedActive };
    });
};

const getIndexFromUniqueIdentifier = (
  uniqueIdentifier: UniqueIdentifier | string,
  index: number
): number => Number(String(uniqueIdentifier).split('_')[index]);

export const getQuestionIndexFromUniqueIdentifier = (
  uniqueIdentifier: UniqueIdentifier | string
): number => getIndexFromUniqueIdentifier(uniqueIdentifier, 1) - 1;

export const getGroupedIndex = (uniqueIdentifier: UniqueIdentifier | string) =>
  getIndexFromUniqueIdentifier(uniqueIdentifier, 2);

export const getNewId = <T extends { id: number }>(
  initial: number,
  arrayWithId: T[]
): number => {
  return arrayWithId.find((element: T) => element.id === initial) === undefined
    ? initial
    : getNewId(initial - 1, arrayWithId);
};

export const getInsertionIndex = (
  groupedQuestions: EditingQuestionOrGroup[],
  questionLength: number,
  groupedIndex?: number
): number => {
  if (groupedIndex !== undefined) return groupedIndex;

  const bottomIndex: number = groupedQuestions.findIndex(
    (questionOrGroup: EditingQuestionOrGroup) =>
      'isDeleted' in questionOrGroup && questionOrGroup.isDeleted
  );
  if (bottomIndex === -1) return questionLength;
  return bottomIndex;
};

export const getDisplayIndex = <T extends { id: number; type: QuestionType }>(
  questions: T[],
  question: T
): number =>
  questions
    .filter((q: T) =>
      question.type === 'message' ? q.type === 'message' : q.type !== 'message'
    )
    .findIndex((q: T) => q.id === question.id);

export const existEmptyItem = (question: EditingQuestion): boolean =>
  SELECTABLE_QUESTION_TYPES.includes(question.type) &&
  question.items!.some(isEmptyItem);

export const existQuestionsWithAllItemsDeleted = (
  question: EditingQuestion
): boolean =>
  SELECTABLE_QUESTION_TYPES.includes(question.type) &&
  question.items!.every(isItemDeleted);

export const isValidTextRange = (range: EditingAnswerRange): boolean =>
  (range.minValue !== undefined || range.maxValue !== undefined) &&
  (range.minValue === undefined || Number(range.minValue) <= MAX_TEXT_LENGTH) &&
  (range.maxValue === undefined || Number(range.maxValue) <= MAX_TEXT_LENGTH);

export const questionIndexesHaveDuplicateHeadline = (
  question: EditingQuestion,
  questions: EditingQuestion[]
): string | undefined => {
  const isItemError: boolean =
    question.items !== undefined &&
    (existQuestionsWithAllItemsDeleted(question) || existEmptyItem(question));

  const areAllMaxValid: boolean =
    question.ranges !== undefined &&
    question.ranges.every((range: EditingAnswerRange) =>
      isMaxNumber(range.maxValue)
    );
  const areAllMinValid: boolean =
    question.ranges !== undefined &&
    question.ranges.every((range: EditingAnswerRange) =>
      isMinNumber(range.minValue)
    );
  const areAllRangesValid: boolean =
    question.ranges !== undefined &&
    question.ranges.every((range: EditingAnswerRange) =>
      isMaxGreaterThanMinWhenNotEqual(
        range.maxValue,
        range.minValue,
        range.isEqual
      )
    );
  const areAllTextRangeValid: boolean =
    !TEXT_QUESTION_TYPES.includes(question.type) ||
    (question.ranges !== undefined && question.ranges.every(isValidTextRange));
  const isRangeError: boolean =
    question.ranges !== undefined &&
    question.ranges.length !== 0 &&
    !(
      areAllMaxValid &&
      areAllMinValid &&
      areAllRangesValid &&
      areAllTextRangeValid
    );
  const isDuplicateHeadline: undefined | string[] =
    getQuestionIndexesHaveDuplicateHeadline(question, questions);

  if (
    question.headline === '' ||
    question.question === '' ||
    isItemError ||
    isRangeError ||
    isDuplicateHeadline !== undefined
  )
    return 'エラーの項目があります';

  return undefined;
};

export const extractKeyQuestion = (
  questions: FetchedQuestion[],
  keyQuestionId: number
): Question => {
  const flattenedQuestions: Question[] = questions.flatMap(
    (question: Question | GroupedQuestion): Question | Question[] =>
      'group' in question ? question.questions : question
  );
  return flattenedQuestions.find(
    //inheritanceで指定されたquestionIdをもとにキー質問のみを抽出
    (question: Question) => question.id === keyQuestionId
  )!;
};

export const createInheritance = (
  isSameUser: boolean,
  inheritanceQuestionIndex: number,
  targetQuestion?: EditingQuestion
): Inheritance => {
  if (inheritanceQuestionIndex === -1) {
    return { isSameUser };
  }
  if ('isDeleted' in targetQuestion!) {
    return { isSameUser, questionId: targetQuestion!.id };
  }

  return { isSameUser, questionIndex: inheritanceQuestionIndex };
};

export const isSameGroup = (
  questions: EditingQuestion[],
  innerQuestionIndex: number,
  groupId: number
): boolean => {
  const innerQuestion: EditingQuestion = questions[innerQuestionIndex - 1];
  return innerQuestion.groupId === groupId;
};

//題名が被っている質問のindexを返す関数
const getIndexesByHeadline = (
  targetQuestion: EditingQuestion,
  questions: EditingQuestion[]
): number[] => {
  const indexes = questions.reduce<number[]>((indexes, question, index) => {
    if (
      question.headline === targetQuestion.headline &&
      targetQuestion.id !== question.id
    ) {
      indexes.push(index);
    }
    return indexes;
  }, []);
  return indexes;
};

export const getQuestionIndexesHaveDuplicateHeadline = (
  targetQuestion: EditingQuestion,
  questions: EditingQuestion[]
): undefined | string[] => {
  if ('isDeleted' in targetQuestion && targetQuestion.isDeleted === true) {
    return undefined;
  }
  //isDeleteがtrueの質問は対象外とする
  const targetQuestions: EditingQuestion[] = questions.filter(
    (question: EditingQuestion) =>
      !('isDeleted' in question && question.isDeleted)
  );

  //メッセージと質問で分割
  const targetQuestionsOnlyMessage: EditingQuestion[] = targetQuestions.filter(
    (question: EditingQuestion) => question.type === 'message'
  );
  const targetQuestionsWithoutMessage: EditingQuestion[] =
    targetQuestions.filter(
      (question: EditingQuestion) => question.type !== 'message'
    );

  //headlineの比較、index抽出
  const targetIndexesOnlyMessage: number[] = getIndexesByHeadline(
    targetQuestion,
    targetQuestionsOnlyMessage
  );
  const targetIndexesWithoutMessage: number[] = getIndexesByHeadline(
    targetQuestion,
    targetQuestionsWithoutMessage
  );

  //質問の何番目、などのstring[]に変換
  const targetOnlyMessage: string[] = targetIndexesOnlyMessage.map(
    (index: number, idx: number) => {
      return `${idx === 0 ? 'メッセージ' : ''}${index + 1}`;
    }
  );
  const targetWithoutMessage: string[] = targetIndexesWithoutMessage.map(
    (index: number, idx: number) => {
      return `${idx === 0 ? '質問' : ''}${index + 1}`;
    }
  );
  return targetWithoutMessage.concat(targetOnlyMessage).length === 0
    ? undefined
    : targetWithoutMessage.concat(targetOnlyMessage);
};
