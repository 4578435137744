import { memo } from 'react';
import Box from '@mui/material/Box';
import Markdown from 'react-markdown';
import SelectForm from './SelectForm';
import RadioForm from './RadioForm';
import CheckForm from './CheckForm';
import TextForm from './TextForm';
import QuestionnaireMessage from './QuestionnaireMessage';
import {
  FetchedQuestion,
  QuestionType,
  Question,
  GroupedQuestion,
  QuestionItem,
  AnswerRange
} from '../../interface/Question';
import { Answer } from '../../interface/Answer';
import NumberForm from './NumberForm';
import { REGEXP_URL } from '../../common/answer';
import { getDisplayIndex } from '../../common/manageQuestion';
import { useSelector } from '../../redux/store';
import { BASE_COLOR } from '../../common/color';

type QuestionProps = {
  question: Question;
  answers: Answer[];
  disabled: boolean;
};

const getItemId = (
  answers: Answer[],
  questionId: number
): number | undefined => {
  const index: number = answers.findIndex(
    (answer: Answer) => answer.questionId === questionId
  );

  if (index === -1) return undefined;

  return answers[index].itemId!;
};

const linkifyDescription = (description: string) => {
  const linkedDescription: string = description.replace(REGEXP_URL, '[$&]($&)');

  if (linkedDescription.match(REGEXP_URL)) return linkedDescription;

  return linkedDescription;
};

const QuestionForm: React.FC<QuestionProps> = memo(
  (props) => {
    const questions: FetchedQuestion[] = useSelector(
      (state) => state.answer.questions
    );
    const question: Question = props.question;
    const answers: Answer[] = props.answers;

    const headline: string = question.headline;
    const description: string = question.question;
    const type: QuestionType = question.type;
    const items: QuestionItem[] | undefined = question.items;
    const required: boolean = question.required;
    const ranges: AnswerRange[] | undefined = question.ranges;

    const linkedDescription = linkifyDescription(description);

    const flattenedQuestions: Question[] = questions.flatMap(
      (question: Question | GroupedQuestion): Question | Question[] =>
        'group' in question ? question.questions : question
    );

    const createFormByType = (type: QuestionType): JSX.Element => {
      if (type === 'select') {
        return (
          <SelectForm
            answer={
              getItemId(answers, question.id) === undefined
                ? ''
                : String(getItemId(answers, question.id))
            }
            items={items!}
            questionId={question.id}
            required={required}
            disabled={props.disabled}
          />
        );
      } else if (type === 'radio') {
        return (
          <RadioForm
            answers={answers}
            items={items!}
            questionId={question.id}
            required={required}
            disabled={props.disabled}
          />
        );
      } else if (type === 'check') {
        return (
          <CheckForm
            answers={answers}
            questionId={question.id}
            items={items!}
            required={required}
            disabled={props.disabled}
          />
        );
      } else if (type === 'number') {
        return (
          <NumberForm
            questionId={question.id}
            required={required}
            answers={answers}
            ranges={ranges}
            disabled={props.disabled}
          />
        );
      } else if (type === 'message') {
        return (
          <QuestionnaireMessage
            questionId={question.id}
            message={linkifyDescription(description)}
            disabled={props.disabled}
          />
        );
      }
      return (
        <TextForm
          questionId={question.id}
          required={required}
          answers={answers}
          ranges={ranges}
          isLongText={type === 'longtext'}
          disabled={props.disabled}
        />
      );
    };

    return (
      <Box sx={{ marginTop: '0.5em' }} className="question">
        <Box display="flex" alignItems={'baseline'}>
          {type !== 'message' && (
            <>
              <Box
                sx={{
                  background: props.disabled ? '#CCCCCC' : BASE_COLOR,
                  color: '#FFFFFF',
                  display: 'inline-block',
                  padding: '0.5em',
                  marginRight: '0.5em',
                  borderRadius: '10px'
                }}
                className="question-index"
              >
                [Q{getDisplayIndex(flattenedQuestions, question) + 1}]{' '}
                {headline}
              </Box>
              <Box
                sx={{
                  whiteSpace: 'pre-wrap',
                  color: props.disabled ? '#CCCCCC' : undefined
                }}
                className="question-description"
              >
                <Markdown>{linkedDescription}</Markdown>
              </Box>
            </>
          )}
        </Box>
        {createFormByType(type)}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.answers) === JSON.stringify(nextProps.answers) &&
    prevProps.disabled === nextProps.disabled
);

export default QuestionForm;
