import { ConditionOption, FilterCondition } from '../interface/AnswersTable';

export const CONDITIONS: {
  condition: ConditionOption;
  name: string;
  isForNumber: boolean;
}[] = [
  { condition: 'like', name: '含む', isForNumber: false },
  { condition: 'notLike', name: '含まない', isForNumber: false },
  { condition: 'strictEqual', name: '等しい', isForNumber: false },
  { condition: 'strictNotEqual', name: '等しくない', isForNumber: false },
  { condition: 'greaterThan', name: 'より大きい', isForNumber: true },
  { condition: 'orMore', name: '以上', isForNumber: true },
  { condition: 'orLess', name: '以下', isForNumber: true },
  { condition: 'lessThan', name: 'より小さい', isForNumber: true }
];

const FILTER_CONDITION_PREFIX: string = `filterConditions_`;
export const getFilterConditionKey = (hash: string): string =>
  FILTER_CONDITION_PREFIX + hash;

export const createNeatFilterConditions = (
  filterConditions: FilterCondition[]
): FilterCondition[] => {
  const neatFilterConditions = filterConditions.filter(
    (filterCondition: FilterCondition) =>
      filterCondition.filterTarget !== 0 &&
      filterCondition.value !== '' &&
      filterCondition.value !== undefined
  );
  return neatFilterConditions;
};
