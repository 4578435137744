import Typography from '@mui/material/Typography';
import { Color } from '../../common/color';

type InformationTagProps = {
  color: Color;
  width: number;
  content: string;
};

const InformationTag: React.FC<InformationTagProps> = (props) => {
  return (
    <Typography
      sx={{
        color: 'InfoBackground',
        fontWeight: 'bold',
        fontSize: '15px',
        border: `solid 4px ${props.color}`,
        borderRadius: '40px',
        background: props.color,
        paddingLeft: '10px',
        width: `${props.width}em`
      }}
      className="question-tag"
    >
      {props.content}
    </Typography>
  );
};

export default InformationTag;
