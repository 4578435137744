import CardActions from '@mui/material/CardActions';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { useDispatch } from 'react-redux';
import { deleteGroup, moveGroup } from '../../redux/slice/QuestionnaireSlice';
import { PreviousInfo } from '../../interface/Question';

type GroupCardActionProps = {
  groupId: number;
  previousInfo: PreviousInfo;
  isBottom: boolean;
};

const GroupCardAction: React.FC<GroupCardActionProps> = (props) => {
  const dispatch = useDispatch();

  const handleGroupOrder = (isUpward: boolean) => () =>
    dispatch(moveGroup({ isUpward, groupId: props.groupId }));
  const handleGroupDelete = () =>
    dispatch(deleteGroup({ groupId: props.groupId }));

  return (
    <CardActions
      sx={{ marginTop: '0.5em' }}
      disableSpacing
      aria-label="group-card-action"
    >
      <ButtonWithToolTip
        title="上へ"
        icon={<ArrowUpwardIcon />}
        onClick={handleGroupOrder(true)}
        className="group-order-upward-button"
        disabled={
          props.previousInfo.groupId === undefined &&
          props.previousInfo.questionId === undefined
        }
      />
      <ButtonWithToolTip
        title="下へ"
        icon={<ArrowDownwardIcon />}
        onClick={handleGroupOrder(false)}
        disabled={props.isBottom}
        className="group-order-downward-button"
      />
      <FormGroup sx={{ marginLeft: 'auto' }} row>
        <ButtonWithToolTip
          title="削除"
          icon={<DeleteIcon />}
          onClick={handleGroupDelete}
          className="delete-group-button"
        />
      </FormGroup>
    </CardActions>
  );
};

export default GroupCardAction;
