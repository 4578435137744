import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import UserAuthorities from './UserAuthorities';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';
import { fetchQuestionnaires } from '../../api';
import AuthorityManager from '../common/AuthorityManager';

type UserAuthorityManagerProps = {
  initialHavingIds: number[];
  havingQuestionnaires: QuestionnaireMetaData[];
  setHavingQuestionnaires: Dispatch<SetStateAction<QuestionnaireMetaData[]>>;
  deleteIds: number[];
  setDeleteIds: Dispatch<SetStateAction<number[]>>;
};

const getTargetQuestionnaire = (
  checkedIds: number[],
  questionnaires: QuestionnaireMetaData[]
): QuestionnaireMetaData[] =>
  checkedIds.map(
    (id: number) =>
      questionnaires.find(
        (questionnaire: QuestionnaireMetaData) => questionnaire.id === id
      )!
  );

const UserAuthorityManager: React.FC<UserAuthorityManagerProps> = (props) => {
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireMetaData[]>(
    []
  );

  const [checkedHavingQuestionnaireIds, setCheckedHavingQuestionnaireIds] =
    useState<number[]>([]);
  const [
    checkedNotHavingQuestionnaireIds,
    setCheckedNotHavingQuestionnaireIds
  ] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      const response = await fetchQuestionnaires(50, 0, false);
      setQuestionnaires(response.questionnaires);
    })();
  }, []);

  const eliminateAuthorities = () => {
    props.setHavingQuestionnaires(
      props.havingQuestionnaires.filter(
        (having: QuestionnaireMetaData) =>
          !checkedHavingQuestionnaireIds.includes(having.id)
      )
    );

    const deleteTarget: number[] = checkedHavingQuestionnaireIds.filter(
      (id: number) => props.initialHavingIds.includes(id)
    );
    props.setDeleteIds(
      Array.from(new Set([...props.deleteIds, ...deleteTarget]))
    );

    setCheckedHavingQuestionnaireIds([]);
  };

  const grantAuthorities = () => {
    props.setHavingQuestionnaires([
      ...props.havingQuestionnaires,
      ...getTargetQuestionnaire(
        checkedNotHavingQuestionnaireIds,
        questionnaires
      )
    ]);

    props.setDeleteIds(
      props.deleteIds.filter(
        (id: number) => !checkedNotHavingQuestionnaireIds.includes(id)
      )
    );

    setCheckedNotHavingQuestionnaireIds([]);
  };

  return (
    <AuthorityManager
      havingAuthorities={
        <UserAuthorities
          questionnaires={props.havingQuestionnaires}
          checkedIds={checkedHavingQuestionnaireIds}
          setCheckedIds={setCheckedHavingQuestionnaireIds}
        />
      }
      notHavingAuthorities={
        <UserAuthorities
          questionnaires={questionnaires.filter(
            (questionnaire: QuestionnaireMetaData) =>
              props.havingQuestionnaires.findIndex(
                (having: QuestionnaireMetaData) =>
                  having.id === questionnaire.id
              ) === -1
          )}
          checkedIds={checkedNotHavingQuestionnaireIds}
          setCheckedIds={setCheckedNotHavingQuestionnaireIds}
        />
      }
      eliminateAuthorities={eliminateAuthorities}
      grantAuthorities={grantAuthorities}
      checkedHavingIds={checkedHavingQuestionnaireIds}
      checkedNotHavingIds={checkedNotHavingQuestionnaireIds}
      paginationButtons={<></>}
    />
  );
};

export default UserAuthorityManager;
