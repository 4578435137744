import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { EditingQuestionItem } from '../../interface/Question';
import QuestionItem from './QuestionItem';

type UnsortableQuestionItemProps = {
  index: number;
  item: EditingQuestionItem;
  editableIndex: number;
  existEmptyItem: boolean;
  existQuestionsWithAllItemsDeleted: boolean;
  setEditableIndex: Dispatch<SetStateAction<number>>;
};

const UnsortableQuestionItem: React.FC<UnsortableQuestionItemProps> = (
  props
) => {
  const {
    index,
    item,
    editableIndex,
    existEmptyItem,
    existQuestionsWithAllItemsDeleted,
    setEditableIndex
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <DragIndicatorIcon fontSize="small" />
      <QuestionItem
        item={item}
        index={index}
        editableIndex={editableIndex}
        setEditableIndex={setEditableIndex}
        existEmptyItem={existEmptyItem}
        existQuestionsWithAllItemsDeleted={existQuestionsWithAllItemsDeleted}
      />
    </Box>
  );
};

export default UnsortableQuestionItem;
