import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { QuestionType } from '../../interface/Question';
import { addGroup, addQuestion } from '../../redux/slice/QuestionnaireSlice';
import QuestionTypeButtons from './QuestionTypeButtons';

const AddQuestionButton: React.FC = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAddGroup = () => dispatch(addGroup({}));

  const onClickAddButton = (type: QuestionType) => () => {
    setAnchorEl(null);
    return dispatch(addQuestion({ type }));
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        className="add-question-card"
        variant="contained"
        sx={{ marginTop: '1em' }}
        startIcon={<AddCircleIcon />}
        onClick={handleClick}
      >
        質問項目追加
      </Button>
      <QuestionTypeButtons
        anchorEl={anchorEl}
        isOpen={open}
        handleClose={handleClose}
        onClick={onClickAddButton}
      />
      <Button
        className="add-group-card"
        variant="contained"
        sx={{ marginTop: '1em' }}
        startIcon={<AddCircleIcon />}
        onClick={handleClickAddGroup}
      >
        グループ追加
      </Button>
      <Button
        className="add-message-card"
        variant="contained"
        sx={{ marginTop: '1em' }}
        startIcon={<AddCircleIcon />}
        onClick={onClickAddButton('message')}
      >
        メッセージ追加
      </Button>
    </Stack>
  );
};

export default AddQuestionButton;
