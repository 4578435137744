import { useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

type OptionImportModalProps = {
  isOpen: boolean;
  handleClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  handleAddNewOptions: Function;
};
type AdditionalMethod = 'new' | 'overwrite';
type DelimiterType = 'comma' | 'line-break';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const getDelimiter = (delimiterType: DelimiterType): string => {
  switch (delimiterType) {
    case 'line-break':
      return '\n';
    case 'comma':
      return ',';
    default:
      throw new Error('Delimiter is not found');
  }
};

const splitNewOptions = (
  delimiterType: DelimiterType,
  newOptionsString: string
): string[] => {
  const delimiter: string = getDelimiter(delimiterType);
  return newOptionsString.split(delimiter);
};

const OptionImportModal: React.FC<OptionImportModalProps> = (props) => {
  const [additionalMethod, setAdditionalMethod] =
    useState<AdditionalMethod>('new');
  const [delimiterType, setDelimiterType] =
    useState<DelimiterType>('line-break');
  const [newOptionsString, setNewOptionsString] = useState<string>('');

  const handleChangeAdditionalMethod = (event: SelectChangeEvent) => {
    setAdditionalMethod(event.target.value as AdditionalMethod);
  };

  const handleChangeDelimiter = (event: SelectChangeEvent) => {
    setDelimiterType(event.target.value as DelimiterType);
  };

  return (
    <Modal open={props.isOpen}>
      <Box sx={style}>
        <FormControl fullWidth>
          <InputLabel>追加方法</InputLabel>
          <Select
            value={additionalMethod}
            label="追加方法"
            onChange={handleChangeAdditionalMethod}
          >
            <MenuItem value="new">新規追加</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginTop: '1em' }} fullWidth>
          <InputLabel>区切り文字</InputLabel>
          <Select
            value={delimiterType}
            label="区切り文字"
            aria-label="delimiter-select"
            onChange={handleChangeDelimiter}
          >
            <MenuItem value="line-break">改行</MenuItem>
            <MenuItem value="comma">カンマ</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="追加選択肢"
          multiline
          minRows={4}
          maxRows={10}
          value={newOptionsString}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setNewOptionsString(event.target.value)}
          fullWidth
          aria-label="new-options-textarea"
          sx={{ marginTop: '1em' }}
        />
        <Stack sx={{ marginTop: '1em' }} spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={() => props.handleClose()}
            color="secondary"
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.handleAddNewOptions(
                splitNewOptions(delimiterType, newOptionsString)
              );
              setNewOptionsString('');
            }}
            aria-label="all-add-button"
          >
            一括追加
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default OptionImportModal;
