import { ChangeEvent, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { QuestionItem } from '../../interface/Question';
import { Answer } from '../../interface/Answer';
import { useDispatch } from 'react-redux';
import { removeAnswer, updateAnswer } from '../../redux/slice/AnswerSlice';
import { handleSelectableTextAnswerChange } from '../../common/answer';
import { getSentenceColor } from '../../common/color';
import { useSelector } from '../../redux/store';

type RadioFormProps = {
  answers: Answer[];
  items: QuestionItem[];
  questionId: number;
  disabled: boolean;
  required?: boolean;
};

const RadioForm: React.FC<RadioFormProps> = (props) => {
  const items: QuestionItem[] = props.items;
  const questionId: number = props.questionId;
  const required: boolean =
    props.required !== undefined ? props.required : false;
  const answers: Answer[] = props.answers;
  const isAnswersSet: boolean = useSelector(
    (state) => state.answer.isAnswersSet
  );

  useEffect(() => {
    const initialTextAnswer: string | undefined = answers.find(
      (answer: Answer) => answer.textAnswer !== undefined
    )?.textAnswer;
    if (initialTextAnswer) setTextAnswer(initialTextAnswer);
    else setTextAnswer('');
  }, [isAnswersSet]);

  const [textAnswer, setTextAnswer] = useState<string>('');

  const dispatch = useDispatch();
  const updateRadioAnswer = (itemId: number, textAnswer?: string) =>
    dispatch(updateAnswer({ questionId, tempPath: '', itemId, textAnswer }));
  const removeRadioAnswer = (itemId: number) =>
    dispatch(removeAnswer({ questionId, itemId }));

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const itemId = Number(event.target.value);
    const newTextAnswer: string | undefined = items.find(
      (item: QuestionItem) => item.id === itemId
    )!.isDescription
      ? textAnswer
      : undefined;
    updateRadioAnswer(itemId, newTextAnswer);
  };

  const handleRadioClick = (id: number) => () => {
    if (answers.some((answer: Answer) => answer.itemId === id)) {
      removeRadioAnswer(id);
    }
  };

  const answer: Answer | undefined = answers.find(
    (answer: Answer) => answer.questionId === questionId
  );

  const isError = answer === undefined && required;

  return (
    <FormControl required={required} error={isError} sx={{ m: 3 }}>
      <RadioGroup
        aria-labelledby="row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleRadioChange}
      >
        {items.map((item: QuestionItem) => {
          if (item.isDescription) {
            return (
              <FormControlLabel
                value={item.id}
                control={
                  <Radio
                    sx={{ color: getSentenceColor(isError) }}
                    onClick={() => {
                      if (textAnswer === '') {
                        return;
                      }

                      handleRadioClick(item.id)();
                    }}
                    value={item.id}
                  />
                }
                checked={
                  answer !== undefined &&
                  answer.itemId === item.id &&
                  textAnswer !== ''
                }
                label={
                  <TextField
                    variant="outlined"
                    value={textAnswer}
                    name={String(item.id)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTextAnswer(event.target.value);
                      handleSelectableTextAnswerChange(
                        updateRadioAnswer,
                        removeRadioAnswer
                      )(event);
                    }}
                    label={item.name}
                    disabled={props.disabled}
                  />
                }
                disabled={props.disabled}
                key={item.id}
              />
            );
          }

          return (
            <FormControlLabel
              value={item.id}
              control={
                <Radio
                  sx={{ color: getSentenceColor(isError) }}
                  onClick={handleRadioClick(item.id)}
                />
              }
              checked={answer !== undefined && answer.itemId === item.id}
              label={item.name}
              key={item.id}
              disabled={props.disabled}
            />
          );
        })}
      </RadioGroup>
      {isError && !props.disabled ? (
        <FormHelperText>1つ選択してください</FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

export default RadioForm;
