import { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectChangeEvent, SxProps } from '@mui/material';
import {
  FetchedQuestion,
  GroupedQuestion,
  Question,
  QuestionItem,
  QuotableAnswer
} from '../../interface/Question';
import { useSelector } from '../../redux/store';
import { Inheritance } from '../../interface/Inheritance';
import {
  extractKeyQuestion,
  getDisplayIndex
} from '../../common/manageQuestion';
import {
  AnswerPerQuestion,
  AnswersTableMetaData,
  FilterCondition
} from '../../interface/AnswersTable';
import { fetchFilteredAnswers } from '../../api';
import { SELECTABLE_QUESTION_TYPES } from '../../common/questionType';

type QuotableAnswersTableProps = {
  isOpen: boolean;
  selectedMetadataId: number;
  changeSelectedMetadataId: (metadataId: number) => void;
  filters: FilterCondition[];
};

const QuotableAnswersTable: React.FC<QuotableAnswersTableProps> = (props) => {
  const { selectedMetadataId, changeSelectedMetadataId, filters } = props;

  const inheritance: Inheritance | undefined = useSelector(
    (state) => state.answer.inheritance
  );
  const questionnaireId: number = useSelector(
    (state) => state.answer.questionnaireId
  );
  const questions: FetchedQuestion[] = useSelector(
    (state) => state.answer.questions
  );

  const [quotableAnswers, setQuotableAnswers] = useState<QuotableAnswer[]>([]);

  const keyQuestion: Question = extractKeyQuestion(
    questions,
    inheritance!.questionId!
  );

  const fetchLatestAnswers = async () => {
    //isLatestとなっている質問のanswersを抽出
    const latestAnswers: AnswersTableMetaData[] = (
      await fetchFilteredAnswers(
        questionnaireId,
        true,
        100,
        0,
        filters.length > 0 ? filters : undefined
      )
    ).answers;

    const keyQuestionAnswers: (AnswerPerQuestion & {
      metadataId: number;
      userName: string;
    })[] = latestAnswers.map((latestAnswer: AnswersTableMetaData) => ({
      //キー質問のisLatestのanswerと該当するメタデータを抽出
      ...latestAnswer.answer.find(
        (answer: AnswerPerQuestion) => answer.id === keyQuestion.id
      )!,
      metadataId: latestAnswer.metadataId,
      userName: latestAnswer.userName
    }));

    const quotableAnswers: QuotableAnswer[] = keyQuestionAnswers.map(
      (
        keyQuestionAnswer: AnswerPerQuestion & {
          metadataId: number;
          userName: string;
        }
      ) => {
        const extractTargetItemName = (
          items: QuestionItem[],
          textAnswer?: string
        ): string => {
          const targetItem: QuestionItem = items.find(
            (item: QuestionItem) => item.name === keyQuestionAnswer.itemName
          )!;

          return targetItem.isDescription ? textAnswer! : targetItem.name;
        };
        const name: string = SELECTABLE_QUESTION_TYPES.includes(
          keyQuestion.type
        )
          ? extractTargetItemName(
              keyQuestion.items!,
              keyQuestionAnswer.textAnswer
            )
          : keyQuestionAnswer.textAnswer!;

        return {
          name,
          metadataId: keyQuestionAnswer.metadataId,
          userName: keyQuestionAnswer.userName
        };
      }
    );
    setQuotableAnswers(quotableAnswers);
  };

  useEffect(() => {
    if (!props.isOpen) return;
    changeSelectedMetadataId(0);
    fetchLatestAnswers();
  }, [props.isOpen, filters]);

  const flattenedQuestions: Question[] = questions.flatMap(
    (question: Question | GroupedQuestion): Question | Question[] =>
      'group' in question ? question.questions : question
  );

  const TABLE_CELL_STYLE: SxProps = {
    maxWidth: '45%',
    width: '45%',
    wordBreak: 'break-all'
  };

  return (
    <RadioGroup
      defaultValue="referenceQuestion"
      value={selectedMetadataId}
      onChange={(event: SelectChangeEvent<number>) => {
        changeSelectedMetadataId(Number(event.target.value));
      }}
      sx={{
        maxHeight: '55vh',
        width: '60vw',
        maxWidth: '75vw',
        overflowY: 'scroll',
        '.key-cell': TABLE_CELL_STYLE,
        '.name-cell': TABLE_CELL_STYLE,
        '.radio-cell': { width: '10%' }
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="radio-cell" />
            <TableCell className="key-cell">
              [Q{getDisplayIndex(flattenedQuestions, keyQuestion) + 1}]
              {keyQuestion.headline}
            </TableCell>
            <TableCell className="name-cell">回答者氏名</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotableAnswers.map((quotableAnswer) => (
            <TableRow key={quotableAnswer.metadataId}>
              <TableCell className="radio-cell">
                <Radio value={quotableAnswer.metadataId} />
              </TableCell>
              <TableCell className="key-cell">{quotableAnswer.name}</TableCell>
              <TableCell className="name-cell">
                {quotableAnswer.userName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </RadioGroup>
  );
};

export default QuotableAnswersTable;
