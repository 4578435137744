import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { QuestionType, QuestionTypeObject } from '../../interface/Question';
import { questionTypes } from '../../common/questionType';

type QuestionTypeButtonsProps = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick: (type: QuestionType) => () => void;
};

const QuestionTypeButtons: React.FC<QuestionTypeButtonsProps> = (props) => {
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.isOpen}
      onClose={props.handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      {questionTypes.map((questionType: QuestionTypeObject) => (
        <MenuItem
          onClick={props.onClick(questionType.type)}
          key={questionType.type}
          className="question-type"
          id={`question-type-${questionType.type}`}
        >
          {questionType.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default QuestionTypeButtons;
