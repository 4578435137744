import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { QuestionType } from '../../interface/Question';
import { addGroup, addQuestion } from '../../redux/slice/QuestionnaireSlice';
import { Box } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import QuestionTypeButtons from './QuestionTypeButtons';

type AddQuestionAndGroupProps = {
  groupedIndex: number;
};

const AddQuestionAndGroupButton: React.FC<AddQuestionAndGroupProps> = (
  props
) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const onClickAddButton = (type: QuestionType) => () => {
    setAnchorEl(null);
    setIsOpen(false);
    return dispatch(
      addQuestion({
        type,
        groupedIndex: props.groupedIndex
      })
    );
  };

  const onClickAddGroupButton = () => {
    setAnchorEl(null);
    setIsOpen(false);
    return dispatch(
      addGroup({
        groupedIndex: props.groupedIndex
      })
    );
  };

  const handleDrawerOpen = (status: boolean) => () => {
    setIsOpen(status);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ opacity: 0, '&:hover': { opacity: 1 } }}
      >
        <ButtonWithToolTip
          className="add-question-group-button"
          title="質問・グループを追加"
          onClick={handleClick}
          icon={<AddBoxOutlined />}
        />
        <Box
          sx={{
            flexGrow: 1,
            borderBottom: 1,
            borderBlockColor: 'grey'
          }}
        ></Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          className="add-question-item-list"
          onClick={handleDrawerOpen(true)}
        >
          質問追加
        </MenuItem>
        <MenuItem className="add-group-list" onClick={onClickAddGroupButton}>
          グループ追加
        </MenuItem>
        <MenuItem
          className="add-message-list"
          onClick={onClickAddButton('message')}
        >
          メッセージ追加
        </MenuItem>
        <QuestionTypeButtons
          anchorEl={anchorEl}
          isOpen={isOpen}
          handleClose={handleClose}
          onClick={onClickAddButton}
        />
      </Menu>
    </>
  );
};

export default AddQuestionAndGroupButton;
