import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  EditingQuestion,
  GroupedEditingQuestion
} from '../../interface/Question';
import { useDispatch } from 'react-redux';
import {
  changeFocusingIndex,
  updateGroupName
} from '../../redux/slice/QuestionnaireSlice';
import { useDroppable } from '@dnd-kit/core';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import GroupCardAction from './GroupCardAction';
import OverlaySortableAccordion from './OverlaySortableAccordion';
import { getDisplayIndex, isSameGroup } from '../../common/manageQuestion';
import { BASE_COLOR, ERROR_COLOR } from '../../common/color';

type QuestionGroupCardProps = {
  groupedQuestion: GroupedEditingQuestion;
  questionIndexOffset: number; // 質問のindexのオフセット
  isBottom: boolean;
  groupedIndex: number;
};

const QuestionGroupCard: React.FC<QuestionGroupCardProps> = (props) => {
  const groupedQuestion: GroupedEditingQuestion = props.groupedQuestion;
  const isGroupedQuestionNameError: boolean =
    props.groupedQuestion.groupName === '';
  const notExistInnerQuestion: boolean =
    props.groupedQuestion.questions.length === 0;

  const dispatch = useDispatch();

  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );

  const { setNodeRef, isOver, active } = useDroppable({
    id: `dropGroup_${props.groupedQuestion.groupId}`
  });

  const handleChangeGroupName = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      updateGroupName({
        groupId: groupedQuestion.groupId,
        name: event.target.value
      })
    );

  const onClickCard = (questionId: number) => {
    dispatch(changeFocusingIndex(questionId));
  };

  return (
    <Stack spacing={2} className="group-card">
      <TextField
        label="グループ名"
        variant="standard"
        sx={{ marginTop: '0.5em', width: '30%' }}
        value={groupedQuestion.groupName}
        onChange={handleChangeGroupName}
        aria-label="headline-text-field"
        error={isGroupedQuestionNameError}
        helperText={
          isGroupedQuestionNameError === true
            ? 'グループ名を入力してください'
            : ''
        }
        className="headline-input"
      />
      {groupedQuestion.isOpen && (
        <Box
          ref={setNodeRef}
          sx={{
            minHeight: '300px',
            bgcolor:
              isOver &&
              (String(active?.id).split('_')[0] === 'question' ||
                String(active?.id).split('_')[0] === 'innerQuestion') &&
              !isSameGroup(
                questions,
                Number(String(active?.id).split('_')[1]),
                groupedQuestion.groupId
              )
                ? ` ${BASE_COLOR}`
                : undefined,
            overflowX: 'auto',
            padding: 2,
            border: !notExistInnerQuestion
              ? '1px solid black'
              : `1px solid ${ERROR_COLOR}`
          }}
        >
          <Stack spacing={2}>
            {groupedQuestion.questions.map(
              (question: EditingQuestion, index: number) => (
                <OverlaySortableAccordion
                  key={index}
                  question={question}
                  index={props.questionIndexOffset + index}
                  displayIndex={getDisplayIndex(questions, question)}
                  onClick={() => onClickCard(question.id)}
                  isTop={index === 0}
                  isBottom={index === groupedQuestion.questions.length - 1}
                  deletable={!(inheritance.questionId === question.id)}
                  existQuestionCondition={
                    question.questionConditions !== undefined &&
                    question.questionConditions.flat().length !== 0
                  }
                  groupedIndex={props.groupedIndex}
                />
              )
            )}
          </Stack>
        </Box>
      )}
      {notExistInnerQuestion && (
        <Typography fontSize={11} color={ERROR_COLOR}>
          グループ内に質問を追加してください
        </Typography>
      )}
      <Divider />
      <GroupCardAction
        groupId={props.groupedQuestion.groupId}
        previousInfo={props.groupedQuestion.nextTo}
        isBottom={props.isBottom}
      />
    </Stack>
  );
};

export default QuestionGroupCard;
