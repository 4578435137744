import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { User } from '../../interface/User';
import { getUser, updateRole } from '../../common/user';
import Headline from '../common/Headline';
import UserInformation from './UserInformation';
import UserAuthorityManager from './UserAuthorityManager';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';
import { fetchQuestionnaireByUserId, updateAuthorities } from '../../api';
import Snackbar from '../common/Snackbar';
import { useSnackbar } from '../../hooks/useSnackbar';

const UserInformationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state;

  const [user, setUser] = useState<User>({
    id,
    name: '',
    role: '',
    email: ''
  });
  const [role, setRole] = useState<string>('一般');
  const [initialHavingIds, setInitialHavingIds] = useState<number[]>([]);
  const [havingQuestionnaires, setHavingQuestionnaires] = useState<
    QuestionnaireMetaData[]
  >([]);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar('');
  const [canSave, setCanSave] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const fetchedUser: User = await getUser(id);

      setUser(fetchedUser);
      setRole(fetchedUser.role);

      const fetchedHavingQuestionnaires: QuestionnaireMetaData[] =
        await fetchQuestionnaireByUserId(fetchedUser.email);
      setHavingQuestionnaires(fetchedHavingQuestionnaires);
      setInitialHavingIds(
        fetchedHavingQuestionnaires.map(
          (questionnaire: QuestionnaireMetaData) => questionnaire.id
        )
      );
    })();
  }, [id]);

  const save = async () => {
    setCanSave(false);
    try {
      if (user.role !== role) {
        await updateRole(user.id, role);
      }

      const targetIds: number[] = havingQuestionnaires.map(
        (questionnaire: QuestionnaireMetaData) => questionnaire.id
      );
      await updateAuthorities(targetIds, deleteIds, user.email, user.name);

      openSnackbar(
        'success',
        'ユーザー情報を更新しました。自動的にユーザー管理画面に遷移します。'
      );
    } catch (error) {
      setCanSave(true);
      openSnackbar('error', 'ユーザー情報を更新できませんでした。');
    }
  };

  const handleSnackbarClose = () => {
    closeSnackbar();

    if (severity === 'success') navigate('/user-management');
  };

  return (
    <>
      <Headline headline="ユーザー情報" />
      <Stack spacing={2} direction="column">
        <UserInformation user={user} role={role} setRole={setRole} />
        <UserAuthorityManager
          initialHavingIds={initialHavingIds}
          havingQuestionnaires={havingQuestionnaires}
          setHavingQuestionnaires={setHavingQuestionnaires}
          deleteIds={deleteIds}
          setDeleteIds={setDeleteIds}
        />
      </Stack>
      <Button
        variant="contained"
        sx={{ marginTop: '1em' }}
        onClick={save}
        disabled={!canSave}
      >
        保存
      </Button>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        severity={severity}
        message={snackbarMessage}
      />
    </>
  );
};

export default UserInformationPage;
