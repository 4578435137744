import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '../common/Snackbar';
import ConfirmModal from '../common/ConfirmModal';
import { FetchedQuestion, Question } from '../../interface/Question';
import { useSelector } from '../../redux/store';
import { useSnackbar } from '../../hooks/useSnackbar';
import { Answer } from '../../interface/Answer';
import { isNumber } from '../../common';
import { isAnswerRangeError, MAX_TEXT_LENGTH } from '../../common/answer';
import { saveTempAnswer } from '../../api';
import { Inheritance } from '../../interface/Inheritance';

type SubmitButtonProps = {
  sendAnswer: Function;
  tempPath: string;
  hiddenQuestionIds: number[];
  hiddenGroupIds: number[];
  isEdited?: boolean;
  afterSubmitPage?: string;
};

const expandQuestionResponse = (
  questionResponse: FetchedQuestion[]
): Question[] => {
  return questionResponse
    .map((question: FetchedQuestion): Question | Question[] => {
      if ('group' in question) {
        return question.questions;
      }

      return question;
    })
    .flat();
};

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const isEdited: boolean =
    props.isEdited !== undefined ? props.isEdited : true;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar(
    '回答の送信が完了しました。自動的に回答一覧画面に遷移します。'
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const presentPath: string = location.pathname;
  const hash: string = location.pathname.split('/')[2];

  const questions: FetchedQuestion[] = useSelector(
    (state) => state.answer.questions
  );
  const questionnaireName: string = useSelector(
    (state) => state.answer.questionnaireName
  );
  const questionnaireId: number = useSelector(
    (state) => state.answer.questionnaireId
  );
  const answers: Answer[] = useSelector((state) => state.answer.answers);
  const editingAnswerText: string = useSelector(
    (state) => state.answer.editingAnswerText
  );
  const editingQuestionId: number = useSelector(
    (state) => state.answer.editingQuestionId
  );
  const editingItemId: number | undefined = useSelector(
    (state) => state.answer.editingItemId
  );
  const inheritance: Inheritance | undefined = useSelector(
    (state) => state.answer.inheritance
  );

  const requiredQuestionIds: number[] = expandQuestionResponse(
    questions.filter(
      (questionOrGroup: FetchedQuestion) =>
        !('group' in questionOrGroup) ||
        ('group' in questionOrGroup &&
          !props.hiddenGroupIds.includes(questionOrGroup.groupId))
    )
  )
    .filter(
      (question: Question) =>
        question.required && !props.hiddenQuestionIds.includes(question.id)
    )
    .map((question: Question): number => question.id);
  const numberQuestions: Question[] = expandQuestionResponse(questions).filter(
    (question: Question) => question.type === 'number'
  );
  const textQuestions: Question[] = expandQuestionResponse(questions).filter(
    (question: Question) =>
      question.type === 'text' || question.type === 'longtext'
  );

  const isFilledRequired: boolean = requiredQuestionIds.every(
    (questionId: number) => {
      if (questionId === editingQuestionId && editingItemId === undefined)
        return editingAnswerText !== '';
      return (
        answers.findIndex(
          (answer: Answer) => answer.questionId === questionId
        ) !== -1
      );
    }
  );

  const isAllNumberAnswerOK: boolean = numberQuestions.every(
    (question: Question) => {
      if (question.id === editingQuestionId)
        return (
          isNumber(editingAnswerText) &&
          !isAnswerRangeError(Number(editingAnswerText), question.ranges)
        );
      const index: number = answers.findIndex(
        (answer) => answer.questionId === question.id
      );
      if (index === -1) return true;

      return (
        isNumber(answers[index].textAnswer!) &&
        !isAnswerRangeError(Number(answers[index].textAnswer!), question.ranges)
      );
    }
  );

  const isAllTextAnswerOK: boolean = textQuestions.every(
    (question: Question) => {
      const index: number = answers.findIndex(
        (answer) => answer.questionId === question.id
      );
      if (index === -1) return true;

      return (
        !isAnswerRangeError(
          answers[index].textAnswer!.length,
          question.ranges
        ) && answers[index].textAnswer!.length <= MAX_TEXT_LENGTH
      );
    }
  );

  const canSubmit =
    isFilledRequired &&
    isAllNumberAnswerOK &&
    isAllTextAnswerOK &&
    !isSubmitted &&
    isEdited;

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const handleSnackbarClose = () => {
    //遷移元のページが回答一覧画面か判断する変数
    const fromAnswerTablePage: boolean =
      location.state !== null && location.state.fromAnswerTablePage !== null
        ? location.state.fromAnswerTablePage
        : false;

    closeSnackbar();

    if (props.afterSubmitPage !== undefined && isSubmitted)
      navigate(props.afterSubmitPage, {
        state: {
          questionnaireName
        }
      });
    if (
      snackbarMessage ===
      '回答の送信が完了しました。自動的に回答一覧画面に遷移します。'
    )
      if (fromAnswerTablePage === true) {
        //遷移元のページが回答一覧だった場合のみフィルタ条件をstateとして渡す
        navigate(`/form-answers-table/${hash}`, {
          state: { previousPath: presentPath }
        });
      } else {
        navigate(`/form-answers-table/${hash}`);
      }
  };

  const existEditingAnswer = (): boolean => {
    return editingQuestionId !== -1 && editingAnswerText !== '';
  };

  const saveTemporary = async () => {
    try {
      const editingAnswer: Answer | undefined =
        editingQuestionId !== -1
          ? {
              questionId: editingQuestionId,
              textAnswer: editingAnswerText,
              itemId: editingItemId
            }
          : undefined;
      const answersExceptEditing: Answer[] = answers.filter(
        (answer: Answer) =>
          answer.questionId !== editingQuestionId ||
          answer.itemId !== editingItemId
      );
      const editingAnswers: Answer[] =
        editingAnswer !== undefined
          ? [...answersExceptEditing, editingAnswer]
          : answersExceptEditing;

      const keyAnswer: Answer | undefined = editingAnswers.find(
        (answer: Answer) => answer.questionId === inheritance?.questionId
      );

      saveTempAnswer(
        questionnaireId,
        JSON.stringify(editingAnswers),
        keyAnswer?.itemId,
        keyAnswer?.textAnswer
      );

      openSnackbar('success', '回答を一時的に保存しました。');
    } catch (e) {
      openSnackbar('error', '一時保存の送信時にエラーが発生しました。');
    }
  };

  const submit = async () => {
    try {
      await props.sendAnswer();

      openSnackbar(
        'success',
        '回答の送信が完了しました。自動的に回答一覧画面に遷移します。'
      );
      setIsSubmitted(true);
    } catch (e) {
      openSnackbar('error', '回答の送信時にエラーが発生しました。');
    }

    handleModalClose();
  };

  const handleCancelModalOpen = () => setIsCancelModalOpen(true);
  const handleCancelModalClose = () => setIsCancelModalOpen(false);

  return (
    <>
      <Stack sx={{ marginTop: '0.5em' }} spacing={2} direction="row">
        {location.state !== null && location.state.answerId !== null && (
          <Button
            id="answer-cancel-button"
            color="secondary"
            variant="contained"
            onClick={handleCancelModalOpen}
          >
            キャンセル
          </Button>
        )}
        <Button
          variant="contained"
          onClick={saveTemporary}
          disabled={
            (answers.length === 0 && !existEditingAnswer()) ||
            isSubmitted ||
            !isEdited
          }
          className="answer-save-temporary"
        >
          一時保存
        </Button>
        <Button
          variant="contained"
          onClick={handleModalOpen}
          disabled={!canSubmit}
          className="answer-submit"
        >
          {`${props.isEdited === undefined ? '回答' : '編集'}`}
        </Button>
      </Stack>
      <ConfirmModal
        isOpen={isModalOpen}
        question="回答を提出してよろしいですか？"
        handleClose={handleModalClose}
        execute={submit}
        quit={handleModalClose}
      />
      <ConfirmModal
        isOpen={isCancelModalOpen}
        question="保存せず回答一覧画面に戻りますか？"
        handleClose={handleCancelModalClose}
        execute={() => navigate(`/form-answers-table/${hash}`)}
        quit={handleCancelModalClose}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        severity={severity}
        message={snackbarMessage}
      />
    </>
  );
};

export default SubmitButton;
