import { memo } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import {
  EditingAnswerRange,
  EditingQuestion,
  EditingQuestionItem,
  QuestionTypeObject
} from '../../interface/Question';
import {
  isMaxNumber,
  isMinNumber,
  isMaxGreaterThanMinWhenNotEqual,
  existQuestionsWithAllItemsDeleted,
  existEmptyItem,
  getQuestionIndexesHaveDuplicateHeadline
} from '../../common/manageQuestion';
import {
  SELECTABLE_QUESTION_TYPES,
  questionTypes
} from '../../common/questionType';
import QuestionCardAction from './QuestionCardAction';
import InformationTag from './InformationTag';
import { BASE_COLOR, ERROR_COLOR } from '../../common/color';

type QuestionCardProps = {
  question: EditingQuestion;
  index: number;
  isDeleted: boolean;
  isTop: boolean;
  isBottom: boolean;
  deletable: boolean;
  existQuestionCondition: boolean;
  questions: EditingQuestion[];
};

const QuestionCard: React.FC<QuestionCardProps> = memo((props) => {
  const question: EditingQuestion = props.question;
  const questions: EditingQuestion[] = props.questions;

  return (
    <Box id={`question-card-${props.index + 1}`}>
      {props.existQuestionCondition ? (
        <InformationTag color={BASE_COLOR} content="分岐条件あり" width={8} />
      ) : (
        <></>
      )}
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <>
                <b>質問の種類: </b>
                {
                  questionTypes.find(
                    (questionType: QuestionTypeObject) =>
                      questionType.type === question.type
                  )!.name
                }
              </>
            }
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <>
                <b>見出し: </b>
                {question.headline}
              </>
            }
          />
        </ListItem>
        {question.headline === '' ? (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            見出しを入力してください
          </FormHelperText>
        ) : getQuestionIndexesHaveDuplicateHeadline(question, questions) ? (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            他の質問と見出しが重複しています（
            {getQuestionIndexesHaveDuplicateHeadline(
              question,
              questions
            )?.join()}
            ）
          </FormHelperText>
        ) : (
          <></>
        )}
        <ListItem disablePadding>
          <ListItemText
            sx={{ whiteSpace: 'pre-wrap' }}
            primary={
              <>
                <Box display="flex" justifyContent="row">
                  <b>質問文: </b>
                  {question.question}
                </Box>
              </>
            }
          />
        </ListItem>
        {question.question === '' ? (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            質問文を入力してください
          </FormHelperText>
        ) : (
          <></>
        )}
      </List>
      {SELECTABLE_QUESTION_TYPES.includes(question.type) &&
      question.items !== undefined ? (
        <>
          <Typography>
            <b>選択肢</b>
          </Typography>
          {existQuestionsWithAllItemsDeleted(question) ? (
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              1つ以上選択肢を作成してください
            </FormHelperText>
          ) : (
            <></>
          )}
          {!existQuestionsWithAllItemsDeleted(question) &&
          existEmptyItem(question) ? (
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              選択肢名を入力してください
            </FormHelperText>
          ) : (
            <></>
          )}
          <List dense={true}>
            {question.items.map((item: EditingQuestionItem, i: number) => (
              <ListItem disablePadding key={`item${i + 1}`}>
                <ListItemText
                  primary={
                    'isDeleted' in item && item.isDeleted ? (
                      <s>{`・${item.name}`}</s>
                    ) : (
                      `・${item.name}`
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <></>
      )}
      {question.ranges !== undefined && question.ranges.length !== 0 ? (
        <>
          <b>回答の{question.type === 'number' ? '範囲' : '文字数'}: </b>
          {!question.ranges.every((range: EditingAnswerRange) =>
            isMaxNumber(range.maxValue)
          ) ? (
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              最大値は数値である必要があります。
            </FormHelperText>
          ) : (
            <></>
          )}
          {!question.ranges.every((range: EditingAnswerRange) =>
            isMinNumber(range.minValue)
          ) ? (
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              最小値は数値である必要があります。
            </FormHelperText>
          ) : (
            <></>
          )}
          {!question.ranges.every((range: EditingAnswerRange) =>
            isMaxGreaterThanMinWhenNotEqual(
              range.maxValue,
              range.minValue,
              range.isEqual
            )
          ) ? (
            <FormHelperText sx={{ color: ERROR_COLOR }}>
              最大値は最小値より大きい値である必要があります。
            </FormHelperText>
          ) : (
            <></>
          )}
          <List dense={true}>
            {question.ranges.map((range: EditingAnswerRange, i: number) => {
              const convertEmptyToX = (str: string): string =>
                str === '' ? 'x' : str;

              const lowerLimit: string =
                range.minValue !== undefined
                  ? `${convertEmptyToX(range.minValue)}${
                      range.includesBoundaryValue ? '以上' : 'より大きい'
                    }`
                  : '';
              const upperLimit: string =
                range.maxValue !== undefined
                  ? `${convertEmptyToX(range.maxValue)}${
                      range.includesBoundaryValue ? '以下' : 'より小さい'
                    }`
                  : '';
              const rangeString: string = range.isEqual
                ? `${convertEmptyToX(range.minValue!)}と等しい`
                : `${lowerLimit}${
                    lowerLimit !== '' && upperLimit !== '' ? 'かつ' : ''
                  }${upperLimit}`;

              return (
                <ListItem disablePadding key={`range${i + 1}`}>
                  <ListItemText
                    primary={`・${rangeString}`}
                    className="range-text"
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        <></>
      )}
      <Divider />
      <QuestionCardAction
        index={props.index}
        isDeleted={props.isDeleted}
        isTop={props.isTop}
        isBottom={props.isBottom}
        required={question.required}
        canInherit={question.canInherit}
        deletable={props.deletable}
        isEditing={false}
      />
    </Box>
  );
});

export default QuestionCard;
