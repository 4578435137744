type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;

export const getInvertedColor = (hexColor: HEX): HEX => {
  // 指定した色からR/G/Bをそれぞれ取得
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);

  // 明るさの計算(0〜255)
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
  // 明るさの計算(0〜100)
  const luminance = brightness / 2.55;

  // 基準値(50)より大きい場合は、黒を返し、それ以外は白を返す
  return luminance > 50 ? '#000000' : '#ffffff';
};

export const BASE_COLOR: Color = '#1976D2';
export const ERROR_COLOR: Color = '#D43636';
export const CHARACTER_COLOR: Color = '#535353';

export const getSentenceColor = (isError: boolean): Color => {
  return isError ? ERROR_COLOR : CHARACTER_COLOR;
};
