import { Dispatch, SetStateAction, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {
  User,
  ListUserResponse,
  UserPaginationToken
} from '../../interface/User';
import { listUsers } from '../../common/user';

type UserFilterTextFieldProps = {
  targetUserName: string;
  setUsers: Dispatch<SetStateAction<User[]>>;
  setTargetUserName: Dispatch<SetStateAction<string>>;
  setPaginationTokens: Dispatch<SetStateAction<UserPaginationToken>>;
  resetChecked?: Function;
};

const UserFilterTextField: React.FC<UserFilterTextFieldProps> = (props) => {
  const resetChecked: Function =
    props.resetChecked === undefined ? () => {} : props.resetChecked;

  const filterUser = async () => {
    const response: ListUserResponse = await listUsers(props.targetUserName);
    props.setUsers(response.users);
    props.setPaginationTokens({
      present: '',
      next: response.paginationToken
    });

    resetChecked();
  };

  return (
    <Stack
      sx={{ marginTop: '0.5em', marginBottom: '0.5em' }}
      spacing={2}
      direction="row"
    >
      <Typography variant="body1" sx={{ marginTop: '1em' }}>
        ユーザー名
      </Typography>
      <TextField
        variant="outlined"
        value={props.targetUserName}
        onChange={(event) => props.setTargetUserName(event.target.value)}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.keyCode === 13) {
            filterUser();
          }
        }}
      />
      <Button
        className="user-search-button"
        onClick={filterUser}
        variant="contained"
      >
        検索
      </Button>
    </Stack>
  );
};

export default UserFilterTextField;
