import { MouseEventHandler } from 'react';
import { SxProps } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ERROR_COLOR } from '../../common/color';

type AccordionProps = {
  headline: string;
  content: JSX.Element | JSX.Element[];
  headlineHelperText?: string;
  headlineSx?: SxProps;
  sx?: SxProps;
  isOpen?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Accordion: React.FC<AccordionProps> = (props) => {
  const headline: string = props.headline;
  const content: JSX.Element | JSX.Element[] = props.content;
  const headlineHelperText: string | undefined = props.headlineHelperText;

  return (
    <MuiAccordion
      expanded={props.isOpen}
      sx={props.sx}
      onChange={props.onChange}
      onClick={props.onClick}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={props.headlineSx}>{headline}</Typography>
        {(headlineHelperText || headlineHelperText !== '') && (
          <FormHelperText sx={{ color: ERROR_COLOR, marginLeft: '1em' }}>
            {headlineHelperText}
          </FormHelperText>
        )}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
