import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TableauDashBoard: React.FC = () => {
  return (
    <Box component="span" sx={{ p: 25, backgroundColor: 'gray' }}>
      <Typography align="center">Tableauダッシュボード</Typography>
    </Box>
  );
};

export default TableauDashBoard;
