import {
  DraggableAttributes,
  DraggableSyntheticListeners
} from '@dnd-kit/core';

export type OverlaySortableSourceProps = {
  // useSortableで受け取るプロパティをつまみ部分に渡すprops
  // undefinedのときはオーバーレイで描画している
  handlerProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
  children: JSX.Element;
};

const OverlaySortableSource: React.FC<OverlaySortableSourceProps> = (props) => {
  const handlerProps = props.handlerProps;

  return (
    <div
      ref={handlerProps?.ref}
      className="mdi mdi-drag"
      style={{
        cursor: handlerProps ? 'grab' : 'grabbing'
      }}
      {...handlerProps?.attributes}
      {...handlerProps?.listeners}
    >
      {props.children}
    </div>
  );
};

export default OverlaySortableSource;
