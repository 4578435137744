import { ChangeEvent } from 'react';
import { Answer } from '../interface/Answer';
import { AnswerRange } from '../interface/Question';

type DescriptionAnswerType = 'text' | 'number' | 'longtext';

export const REGEXP_URL =
  /(?<!\[.*\]\()(https?:\/\/[\w/:%#$&?()~.=+-]+)(?!(\]|\)))/g;

export const MAX_TEXT_LENGTH: number = 2000;

export const isAnswerRangeError = (
  numberToRangeCheck: number,
  ranges?: AnswerRange[]
): boolean => {
  if (ranges === undefined) return false;

  return ranges
    .map((range: AnswerRange): boolean => {
      if (range.includesBoundaryValue)
        return (
          (range.minValue === undefined
            ? false
            : !(range.minValue <= numberToRangeCheck)) ||
          (range.maxValue === undefined
            ? false
            : !(numberToRangeCheck <= range.maxValue))
        );

      return (
        (range.minValue === undefined
          ? false
          : !(range.minValue < numberToRangeCheck)) ||
        (range.maxValue === undefined
          ? false
          : !(numberToRangeCheck < range.maxValue))
      );
    })
    .every((err: boolean) => err);
};

export const isTextAnswerRangeError = (
  answerLength: number,
  ranges?: AnswerRange[]
): boolean => {
  return (
    answerLength > MAX_TEXT_LENGTH || isAnswerRangeError(answerLength, ranges)
  );
};

const isEqualCondition = (range: AnswerRange): boolean => {
  return (
    range.minValue !== undefined &&
    range.maxValue !== undefined &&
    range.minValue === range.maxValue &&
    range.includesBoundaryValue
  );
};

export const generateAnswerRangeErrorMessage = (
  type: DescriptionAnswerType,
  ranges?: AnswerRange[]
): string => {
  if (ranges === undefined) return '';

  return ranges
    .map((range: AnswerRange): string => {
      if (range.includesBoundaryValue && isEqualCondition(range)) {
        return `${range.minValue}${
          type === 'number' ? 'を' : '文字で'
        }入力してください。`;
      }

      if (range.includesBoundaryValue) {
        const upperLimitMessage: string =
          range.minValue === undefined ? '' : String(range.minValue) + '以上';
        const lowerLimitMessage: string =
          range.maxValue === undefined ? '' : String(range.maxValue) + '以下';
        return `${upperLimitMessage}${lowerLimitMessage}の${
          type === 'number' ? '値' : '文字数'
        }で回答を入力してください。`;
      }

      const upperLimitMessage: string =
        range.minValue === undefined
          ? ''
          : String(range.minValue) +
            `${type === 'number' ? 'より大きい値' : '文字より多い文字数'}`;

      const lowerLimitMessage: string =
        range.maxValue === undefined
          ? ''
          : String(range.maxValue) +
            `${type === 'number' ? 'より小さい値' : '文字より少ない文字数'}`;

      const existBothMaxAndMin: boolean =
        range.minValue !== undefined && range.maxValue !== undefined;

      return `${upperLimitMessage}${
        existBothMaxAndMin ? 'かつ' : ''
      }${lowerLimitMessage}で回答を入力してください。`;
    })
    .join('\nまたは');
};

export const generateTextAnswerRangeErrorMessage = (
  ranges?: AnswerRange[]
): string => {
  const rangeErrorMessage: string = generateAnswerRangeErrorMessage(
    'text',
    ranges
  );
  const DEFAULT_MESSAGE: string = 'テキスト形式の回答は最大2000文字までです。';

  if (rangeErrorMessage === '') return DEFAULT_MESSAGE;

  return `${DEFAULT_MESSAGE}\n${rangeErrorMessage}`;
};

export const getTextAnswer = (
  answers: Answer[],
  questionId: number,
  itemId?: number
): string => {
  const isSameItemId = (answer: Answer): boolean =>
    itemId ? answer.itemId === itemId : true;
  const answer: Answer | undefined = answers.find(
    (answer: Answer) => answer.questionId === questionId && isSameItemId(answer)
  );
  return answer !== undefined ? answer.textAnswer! : '';
};

export const handleSelectableTextAnswerChange =
  (
    updateAnswer: (itemId: number, text: string) => void,
    removeAnswer: (itemId: number) => void
  ) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const text: string = event.target.value;
    const itemId: number = Number(event.target.name);

    if (text !== '') {
      updateAnswer(itemId, text);
    } else {
      removeAnswer(itemId);
    }
  };
