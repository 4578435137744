import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { AmplifyProvider, Loader } from '@aws-amplify/ui-react';
import { AmplifyUser } from '@aws-amplify/ui';
import Box from '@mui/material/Box';
import Header from './components/header/Header';
import awsConfig from './common/aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { PagePath } from './interface/Page';
import Breadcrumbs from './components/common/Breadcrumbs';
import Sorry from './components/sorry/Sorry';
import { getItemFromLocalStorage } from './common/localStorageHandler';

type AppProps = {
  component: JSX.Element;
  pagePaths: PagePath[];
};

Amplify.configure(awsConfig);

const App: React.FC<AppProps> = (props) => {
  const component: JSX.Element = props.component;

  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState<AmplifyUser | null>(null);
  const [authInProgress, setAuthInProgress] = useState(false);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data);
          setAuthInProgress(false);
          break;
        case 'signOut':
          setUser(null);
          setAuthInProgress(false);
          break;
        default:
      }

      if (getItemFromLocalStorage('path') !== null) {
        const path: string = getItemFromLocalStorage('path')!;
        localStorage.removeItem('path');
        navigate(path);
      }
    });

    if (location.pathname !== '/') {
      localStorage.setItem('path', `${location.pathname}${location.search}`);
    }

    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => {
        window.location.href = `https://${awsConfig.oauth.domain}/login?response_type=${awsConfig.oauth.responseType}&client_id=${awsConfig.aws_user_pools_web_client_id}&redirect_uri=${awsConfig.oauth.redirectSignIn}`;
        setAuthInProgress(true);
      });

    return unsubscribe;
  }, []);

  const isUnderMaintenance: boolean =
    (window as any)._env_.REACT_APP_IS_UNDER_MAINTENANCE === 'true' &&
    user !== null &&
    user.attributes !== undefined &&
    user.attributes!['custom:role'] !== '管理者';

  return (
    <AmplifyProvider colorMode="dark">
      {authInProgress ||
      user === null ||
      (user !== null && user.attributes === undefined) ? (
        <Loader width="5rem" height="5rem" />
      ) : (
        <>
          {isUnderMaintenance ? (
            <Sorry />
          ) : (
            <>
              <Header user={user!} />
              <Box sx={{ marginTop: '65px' }}>
                <Breadcrumbs paths={props.pagePaths} />
                {component}
              </Box>
            </>
          )}
        </>
      )}
    </AmplifyProvider>
  );
};

export default App;
