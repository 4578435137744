import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import SaveIcon from '@mui/icons-material/Save';
import { isEqual } from 'lodash';
import Snackbar from '../common/Snackbar';
import {
  EditingAnswerRange,
  EditingQuestion,
  EditingQuestionOrGroup,
  GroupedEditingQuestion,
  QuestionCondition,
  QuestionGroup
} from '../../interface/Question';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useSelector } from '../../redux/store';
import { Inheritance } from '../../interface/Inheritance';
import {
  groupQuestions,
  isEmptyItem,
  isItemDeleted,
  isMaxGreaterThanMinWhenNotEqual,
  isMaxNumber,
  isMinNumber,
  isQuestionWithItem,
  isValidTextRange
} from '../../common/manageQuestion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { switchIsSaved } from '../../redux/slice/QuestionnaireSlice';
import { TEXT_QUESTION_TYPES } from '../../common/questionType';

type SaveButtonProps = {
  save: Function;
  canSave?: boolean;
};

const SAVE_SUCCESS_MESSAGE = '変更を保存しました。';

const isValidRange = (range: EditingAnswerRange): boolean => {
  return (
    isMaxNumber(range.maxValue) &&
    isMinNumber(range.minValue) &&
    isMaxGreaterThanMinWhenNotEqual(
      range.maxValue,
      range.minValue,
      range.isEqual
    )
  );
};

const canSave = (
  questionnaireName: string,
  questions: EditingQuestion[],
  editingQuestion: EditingQuestion,
  editingIndex: number,
  isInheritanceError: boolean,
  addedSaveCondition: boolean,
  groupNames: string[],
  isQuestionsInGroup: boolean
): boolean => {
  const existQuestionnaireName: boolean = questionnaireName !== '';
  const existAtLeastOneQuestion: boolean = questions.length > 0;
  if (!existQuestionnaireName || !existAtLeastOneQuestion) return false;

  if (questionnaireName.length > 200) return false;

  if (groupNames.includes('')) return false;

  if (!isQuestionsInGroup) return false;

  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const existAllHeadline: boolean = validationTarget.every(
    (question) => question.headline !== ''
  );
  if (!existAllHeadline) return false;

  const existAllQuestionBody: boolean = validationTarget.every(
    (question) => question.question !== ''
  );
  if (!existAllQuestionBody) return false;

  const allRanges: EditingAnswerRange[] = validationTarget
    .filter((question: EditingQuestion) => question.ranges !== undefined)
    .flatMap((question: EditingQuestion) => question.ranges!);
  const areRangesValid: boolean = allRanges.every(isValidRange);
  if (!areRangesValid) return false;

  const textRanges: EditingAnswerRange[] = validationTarget
    .filter(
      (question: EditingQuestion) =>
        TEXT_QUESTION_TYPES.includes(question.type) &&
        question.ranges !== undefined
    )
    .flatMap((question: EditingQuestion) => question.ranges!);
  const areTextRangesValid: boolean = textRanges.every(isValidTextRange);
  if (!areTextRangesValid) return false;

  const hasDuplicateHeadlines = (questions: EditingQuestion[]): boolean => {
    //isDeleteがtrueの質問は対象外とする
    const targetQuestions: EditingQuestion[] = questions.filter(
      (question: EditingQuestion) =>
        !('isDeleted' in question && question.isDeleted)
    );
    const headlines: string[] = targetQuestions.map(
      (question: EditingQuestion) => question.headline
    );
    const uniqueHeadlines: string[] = Array.from(new Set(headlines));
    return headlines.length !== uniqueHeadlines.length;
  };
  if (hasDuplicateHeadlines(validationTarget)) return false;

  const isEditingQuestionEdited: boolean =
    editingIndex !== -1 && !isEqual(editingQuestion, questions[editingIndex]);

  return !isInheritanceError && (addedSaveCondition || isEditingQuestionEdited);
};

const SaveButton: React.FC<SaveButtonProps> = (props) => {
  const addedSaveCondition: boolean =
    props.canSave === undefined ? true : props.canSave;

  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );

  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const isSaved: boolean = useSelector((state) => state.questionnaire.isSaved);

  const dispatch = useDispatch();
  const setSavedFlag = () => dispatch(switchIsSaved());

  const groupNames: string[] | undefined = groups.map((group) => group.name);

  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar(SAVE_SUCCESS_MESSAGE);

  const navigate = useNavigate();
  const location = useLocation();
  const isEditPage: boolean = location.pathname.split('/')[1] === 'form-edit';

  const isInheritanceError: boolean =
    inheritance.questionId! === 0 && !inheritance.isSameUser;

  const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
    questions,
    groups
  );
  // 質問の情報があるグループ
  const groupsInQuestions: GroupedEditingQuestion[] = groupedQuestions.filter(
    (groupOrQuestion: EditingQuestionOrGroup) => 'groupName' in groupOrQuestion
  ) as GroupedEditingQuestion[];
  // グループの中に質問が存在するかを判定する
  const isQuestionsInGroup: boolean = groupsInQuestions
    .map((group: GroupedEditingQuestion) => {
      return group.questions.length;
    })
    .every((questionLength: number) => questionLength !== 0);

  const handleSnackbarClose = () => {
    closeSnackbar();

    // 新規作成画面なら保存後フォーム管理画面に移動する
    if (snackbarMessage === SAVE_SUCCESS_MESSAGE && !isEditPage)
      navigate('/form-management');
  };

  const existItems = (question: EditingQuestion, index: number): boolean => {
    if (
      question.items === undefined ||
      (editingIndex === index && editingQuestion.items === undefined)
    )
      return false;
    return true;
  };

  const convertQuestionIdToIndex = (questionIds: number[]): number[] => {
    return questions
      .map((question: EditingQuestion, index) =>
        questionIds.includes(question.id) ? index + 1 : -1
      )
      .filter((number) => number !== -1);
  };

  const checkChildQuestionNumbersLessThanParentNumber = (): number[] => {
    const childQuestionIdsLessThanParentNumber: number[] = questions
      .filter((question: EditingQuestion, index: number) => {
        if (
          (editingIndex === index &&
            editingQuestion.questionConditions === undefined) ||
          (editingIndex !== index && question.questionConditions === undefined)
        ) {
          return false;
        }

        const getChildQuestionIds = (
          editingQuestion: EditingQuestion
        ): number[] =>
          editingQuestion
            .questionConditions!.filter(
              (condition: QuestionCondition) =>
                condition.childQuestionId !== undefined
            )
            .map((condition: QuestionCondition) => condition.childQuestionId!);

        const childQuestionIds: number[] = getChildQuestionIds(
          editingIndex === index ? editingQuestion : question
        );
        const childQuestionIndexes: number[] =
          convertQuestionIdToIndex(childQuestionIds);
        return childQuestionIndexes.some(
          (childQuestionIndex: number) => childQuestionIndex < index + 1
        );
      })
      .map((question: EditingQuestion) => question.id);

    return convertQuestionIdToIndex(childQuestionIdsLessThanParentNumber);
  };

  const onClickSaveButton = async () => {
    const questionIdsWithEmptyItems: number[] = questions
      .filter((question: EditingQuestion, index: number) => {
        if (!existItems(question, index)) return false;

        if (!isQuestionWithItem(question)) return false;

        if (editingIndex === index)
          return editingQuestion.items!.some(isEmptyItem);

        return question.items!.some(isEmptyItem);
      })
      .map((question) => question.id);

    const questionNumbersWithEmptyItems: number[] = convertQuestionIdToIndex(
      questionIdsWithEmptyItems
    );

    const existEmptyItem: boolean = questionNumbersWithEmptyItems.length > 0;

    if (existEmptyItem) {
      openSnackbar(
        'error',
        `${questionNumbersWithEmptyItems
          .map((number) => '質問' + String(number))
          .join(', ')}の中に空になっている選択肢名があります。`
      );
      return;
    }

    const questionIdsWithAllItemsDeleted: number[] = questions
      .filter((question: EditingQuestion, index: number) => {
        if (!existItems(question, index)) return false;

        if (!isQuestionWithItem(question)) return false;

        if (editingIndex === index)
          return editingQuestion.items!.every(isItemDeleted);
        return question.items!.every(isItemDeleted);
      })
      .map((question: EditingQuestion) => question.id);

    const questionNumbersWithAllItemsDeleted: number[] =
      convertQuestionIdToIndex(questionIdsWithAllItemsDeleted);

    const existQuestionsWithAllItemsDeleted: boolean =
      questionNumbersWithAllItemsDeleted.length > 0;

    if (existQuestionsWithAllItemsDeleted) {
      openSnackbar(
        'error',
        `${questionNumbersWithAllItemsDeleted
          .map((number) => '質問' + String(number))
          .join(', ')}の選択肢が一つも存在していません。`
      );
      return;
    }

    const childQuestionNumbersLessThanParentNumbers: number[] =
      checkChildQuestionNumbersLessThanParentNumber();
    const existChildNumberLessThanParentNumber: boolean =
      childQuestionNumbersLessThanParentNumbers.length > 0;

    if (existChildNumberLessThanParentNumber) {
      openSnackbar(
        'error',
        `${childQuestionNumbersLessThanParentNumbers
          .map(
            (childQuestionNumber: number) =>
              '質問' + String(childQuestionNumber)
          )
          .join(
            ', '
          )}の分岐先の質問に、分岐元よりも前の順序の質問が設定されています。`
      );
      return;
    }

    try {
      await props.save();
      setSavedFlag();
      openSnackbar('success', SAVE_SUCCESS_MESSAGE);
    } catch (error) {
      openSnackbar('error', 'エラーが発生したため、保存できませんでした。');
    }
  };

  const areAllQuestionsDeleted: boolean = questions.every(
    (question: EditingQuestion) => 'isDeleted' in question && question.isDeleted
  );

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        id="save-button"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16
        }}
        onClick={onClickSaveButton}
        disabled={
          areAllQuestionsDeleted ||
          !canSave(
            questionnaireName,
            questions,
            editingQuestion,
            editingIndex,
            isInheritanceError,
            addedSaveCondition,
            groupNames,
            isQuestionsInGroup
          ) ||
          isSaved
        }
      >
        <SaveIcon />
        保存
      </Fab>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        severity={severity}
        message={
          snackbarMessage === SAVE_SUCCESS_MESSAGE ? (
            <>
              {snackbarMessage}
              {isEditPage ? (
                <>
                  フォーム管理画面に移動する場合は
                  <Link href="/form-management" color="inherit">
                    こちら
                  </Link>
                  をクリックしてください。
                </>
              ) : (
                '自動的にフォーム管理画面に移動します。'
              )}
            </>
          ) : (
            snackbarMessage
          )
        }
      />
    </>
  );
};

export default SaveButton;
