import { SyntheticEvent } from 'react';
import CardActions from '@mui/material/CardActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { updateEditingQuestion } from '../../redux/slice/QuestionnaireSlice';
import SwitchOrderButtons from './SwitchOrderButtons';
import EditButtons from './EditButtons';

type QuestionCardActionProps = {
  index: number;
  isDeleted: boolean;
  isTop: boolean;
  isBottom: boolean;
  isEditing: boolean;
  required?: boolean;
  canInherit?: boolean;
  deletable?: boolean;
  isQuestionWithItem?: boolean;
};

const QuestionCardAction: React.FC<QuestionCardActionProps> = (props) => {
  const dispatch = useDispatch();

  const updateQuestion =
    (key: string) =>
    (event: SyntheticEvent<Element, Event>, checked: boolean) =>
      dispatch(updateEditingQuestion({ key, value: checked }));

  return (
    <CardActions
      sx={{ marginTop: '0.5em' }}
      disableSpacing
      aria-label="question-card-action"
    >
      <SwitchOrderButtons
        index={props.index}
        isTop={props.isTop}
        isBottom={props.isBottom}
        isDeleted={props.isDeleted}
        type="question"
      />
      <FormGroup sx={{ marginLeft: 'auto' }} row>
        <FormControlLabel
          control={<Switch disabled={props.isDeleted || !props.isEditing} />}
          label="必須"
          checked={props.required}
          onChange={updateQuestion('required')}
        />
        <FormControlLabel
          control={<Switch disabled={props.isDeleted || !props.isEditing} />}
          label="前回回答の反映"
          checked={props.canInherit}
          onChange={updateQuestion('canInherit')}
          className="inheritance-switch"
        />
        <EditButtons
          isDeleted={props.isDeleted}
          deletable={props.deletable}
          index={props.index}
          isEditing={props.isEditing}
          isQuestionWithItem={props.isQuestionWithItem}
          type="question"
        />
      </FormGroup>
    </CardActions>
  );
};

export default QuestionCardAction;
