import QuestionnaireForm from './QuestionnaireForm';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { submitNewAnswers } from '../../api';
import { Answer, QuestionConditionForAnswer } from '../../interface/Answer';
import { useSelector } from '../../redux/store';
import { useMemo } from 'react';
import {
  ExistingQuestion,
  FetchedQuestion,
  GroupedQuestion,
  Question,
  QuestionCondition
} from '../../interface/Question';
import { completelyExpandQuestionResponse } from '../../common/manageQuestion';

const AnswerPage: React.FC = () => {
  const location = useLocation();
  const hash: string = location.pathname.split('/')[2];

  const tempPath: string = `tempAnswer-${hash}`;

  const answers: Answer[] = useSelector((state) => state.answer.answers);
  const questionnaireId: number = useSelector(
    (state) => state.answer.questionnaireId
  );
  const questions: FetchedQuestion[] = useSelector(
    (state) => state.answer.questions
  );

  const allQuestionConditions: QuestionConditionForAnswer[] = useMemo(
    () =>
      completelyExpandQuestionResponse(questions).flatMap(
        (question: ExistingQuestion) => {
          if (question.questionConditions) {
            return question.questionConditions.map(
              (questionCondition: QuestionCondition) => ({
                parentQuestionId: question.id,
                childQuestionId: questionCondition.childQuestionId,
                questionItemId: questionCondition.questionItemId,
                childGroupId: questionCondition.childGroupId
              })
            );
          }
          return [];
        }
      ),
    [questions]
  );

  const answerSelectedItemIds = useMemo(() => {
    const itemIds = answers.map((answer) => answer.itemId);
    return new Set(itemIds.filter((itemId) => itemId !== undefined));
  }, [
    answers
      .map((answer) => answer.itemId)
      .filter((itemId) => itemId !== undefined)
      .join(',')
  ]);

  const hiddenQuestionIds = useMemo(() => {
    const childIdsToRemove: Set<number> = new Set(
      allQuestionConditions
        .filter(
          (questionCondition: QuestionConditionForAnswer) =>
            answerSelectedItemIds.has(questionCondition.questionItemId) &&
            questionCondition.childQuestionId !== undefined
        )
        .map((questionCondition) => questionCondition.childQuestionId!)
    );

    const filteredConditions: QuestionConditionForAnswer[] =
      allQuestionConditions.filter(
        (questionCondition: QuestionConditionForAnswer) =>
          questionCondition.childQuestionId !== undefined &&
          !childIdsToRemove.has(questionCondition.childQuestionId)
      );
    const hiddenQuestionIds: Set<number> = new Set(
      filteredConditions
        .filter(
          (questionCondition: QuestionConditionForAnswer) =>
            questionCondition.childQuestionId !== undefined
        )
        .map((questionCondition) => questionCondition.childQuestionId!)
    );

    allQuestionConditions.forEach((questionCondition) => {
      if (
        hiddenQuestionIds.has(questionCondition.parentQuestionId) &&
        questionCondition.childQuestionId !== undefined
      ) {
        hiddenQuestionIds.add(questionCondition.childQuestionId);
      }
    });

    return hiddenQuestionIds;
  }, [allQuestionConditions, answerSelectedItemIds]);

  const hiddenGroupIds: number[] = useMemo(() => {
    const childGroupIdSet: Set<number> = new Set(
      allQuestionConditions
        .filter(
          (condition: QuestionConditionForAnswer) =>
            condition.childGroupId !== undefined
        )
        .map((condition: QuestionConditionForAnswer) => condition.childGroupId!)
    );

    const isGroupHidden = (groupId: number): boolean => {
      const conditionsForTheGroup: QuestionConditionForAnswer[] =
        allQuestionConditions.filter(
          (condition: QuestionConditionForAnswer) =>
            condition.childGroupId === groupId
        );

      return conditionsForTheGroup.every(
        (condition: QuestionConditionForAnswer) =>
          !answerSelectedItemIds.has(condition.questionItemId)
      );
    };

    return Array.from(childGroupIdSet).filter(isGroupHidden);
  }, [allQuestionConditions, answerSelectedItemIds]);

  const sendAnswer = async () => {
    const questionIdsInHiddenGroups: number[] = questions
      .filter(
        (questionOrGroup: FetchedQuestion) =>
          'group' in questionOrGroup &&
          hiddenGroupIds.includes(questionOrGroup.groupId)
      )
      .flatMap((questionOrGroup: FetchedQuestion) =>
        (questionOrGroup as GroupedQuestion).questions.map(
          (question: Question) => question.id
        )
      );
    const removeHiddenAnswers = answers.filter(
      (answer) =>
        !hiddenQuestionIds.has(answer.questionId) &&
        !questionIdsInHiddenGroups.includes(answer.questionId)
    );
    const user = await Auth.currentAuthenticatedUser();
    await submitNewAnswers({
      userId: user.attributes.email,
      questionnaireId: questionnaireId,
      hash: hash,
      answers: removeHiddenAnswers
    });
  };

  return (
    <QuestionnaireForm
      sendAnswer={sendAnswer}
      tempPath={tempPath}
      isInheritance={true}
      hiddenQuestionIds={[...hiddenQuestionIds]}
      hiddenGroupIds={hiddenGroupIds}
    />
  );
};

export default AnswerPage;
