import { QuestionType, QuestionTypeObject } from '../interface/Question';

export const questionTypes: QuestionTypeObject[] = [
  { type: 'select', name: 'プルダウン' },
  { type: 'radio', name: 'ラジオボタン' },
  { type: 'check', name: 'チェックボックス' },
  { type: 'text', name: 'テキスト(短文)' },
  { type: 'longtext', name: 'テキスト(段落)' },
  { type: 'number', name: '数字' }
];

export const SELECTABLE_QUESTION_TYPES: QuestionType[] = [
  'select',
  'radio',
  'check'
];
export const DESCRIBABLE_QUESTION_TYPES: QuestionType[] = [
  'text',
  'number',
  'longtext'
];
export const TEXT_QUESTION_TYPES: QuestionType[] = ['text', 'longtext'];
