import { SyntheticEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete, {
  AutocompleteChangeReason
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import _ from 'lodash';
import { QuestionItem } from '../../interface/Question';
import { useDispatch } from 'react-redux';
import { removeAnswer, updateAnswer } from '../../redux/slice/AnswerSlice';

type SelectFormProps = {
  answer: string;
  questionId: number;
  items: QuestionItem[];
  disabled: boolean;
  required?: boolean;
};

const SelectForm: React.FC<SelectFormProps> = (props) => {
  const answer: string = props.answer;
  const required: boolean =
    props.required !== undefined ? props.required : false;
  const items: QuestionItem[] = props.items;
  const dispatch = useDispatch();

  const updateSelectAnswer = (itemId: number) =>
    dispatch(
      updateAnswer({ questionId: props.questionId, tempPath: '', itemId })
    );
  const removeSelectAnswer = (itemId: number) =>
    dispatch(removeAnswer({ questionId: props.questionId, itemId }));

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' && !_.isEqual(value, { label: '', id: 0 })) {
      updateSelectAnswer((value as { label: string; id: number }).id);
    } else if (
      reason === 'clear' ||
      (reason === 'selectOption' && _.isEqual(value, { label: '', id: 0 }))
    )
      removeSelectAnswer(Number(answer));
  };

  const isError = answer === '' && required;

  const getAnswerItemName = (id: number): string => {
    const answerItem: QuestionItem | undefined = items.find(
      (item: QuestionItem) => item.id === Number(answer)
    );
    return answerItem === undefined ? '' : answerItem.name;
  };

  const options = [
    { label: '', id: 0 },
    ...items.map((item: QuestionItem) => ({
      label: item.name,
      id: item.id
    }))
  ];

  return (
    <FormControl sx={{ width: '30%', marginTop: '0.5em' }} error={isError}>
      <Autocomplete
        disablePortal
        options={options}
        value={{
          label: getAnswerItemName(Number(answer)),
          id: Number(answer)
        }}
        isOptionEqualToValue={(option, value) => _.isEqual(option, value)}
        onChange={handleSelectChange}
        renderInput={(params: any) => <TextField {...params} />}
        noOptionsText="候補がありません"
        disabled={props.disabled}
      />
      {isError && !props.disabled ? (
        <FormHelperText>1つ選択してください</FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

export default SelectForm;
