import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

type KeyQuestionChangeModalProps = {
  isOpen: boolean;
  existTemporarySavedAnswers: boolean;
  handleClose: () => void;
  reflectPreviousAnswers: () => void;
  reflectTemporarySavedAnswers: () => void;
  quit: () => void;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const KeyQuestionChangeModal: React.FC<KeyQuestionChangeModalProps> = (
  props
) => {
  return (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {props.existTemporarySavedAnswers
            ? [
                'この項目の選択した回答には一時的に保存された回答が存在します。',
                <br key="br key 1" />,
                <strong key="strong key">前回の内容で回答を</strong>,
                '上書きするか、一時保存の情報を反映するかを選択できます。',
                <br key="br key 2" />,
                '現在記入中の回答は消えますが、よろしいですか？'
              ]
            : [
                'この項目を変更すると、選んだ選択肢の',
                <strong key="strong key">前回の内容で回答を</strong>,
                '上書きします。',
                <br key="br key" />,
                '現在記入中の回答は消えますが、よろしいですか？'
              ]}
        </Typography>
        <Stack sx={{ marginTop: '0.5em' }} spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={props.quit}
            color="secondary"
            className="confirm-no"
          >
            キャンセル
          </Button>
          {props.existTemporarySavedAnswers && (
            <Button
              variant="contained"
              onClick={props.reflectTemporarySavedAnswers}
              className="reflect-temporary-answer-button"
            >
              一時保存の情報を採用
            </Button>
          )}
          <Button
            variant="contained"
            onClick={props.reflectPreviousAnswers}
            className="confirm-yes"
          >
            前回回答を反映
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default KeyQuestionChangeModal;
