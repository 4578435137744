import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import {
  addAnswer,
  removeAnswer,
  updateDescriptionAnswer
} from '../../redux/slice/AnswerSlice';
import { AnswerRange } from '../../interface/Question';
import {
  generateTextAnswerRangeErrorMessage,
  getTextAnswer,
  isTextAnswerRangeError
} from '../../common/answer';
import { Answer } from '../../interface/Answer';

type TextFormProps = {
  questionId: number;
  answers: Answer[];
  isLongText: boolean;
  disabled: boolean;
  required?: boolean;
  ranges?: AnswerRange[];
};

const TextForm: React.FC<TextFormProps> = (props) => {
  const required: boolean =
    props.required === undefined ? false : props.required;
  const answer: string = getTextAnswer(props.answers, props.questionId);
  const isEmptyError: boolean = required && answer === '';
  const isError: boolean =
    isEmptyError || isTextAnswerRangeError(answer.length, props.ranges);

  const dispatch = useDispatch();
  const updateAnswer = (textAnswer: string) =>
    dispatch(
      updateDescriptionAnswer({ questionId: props.questionId, textAnswer })
    );
  const removeTextAnswer = () =>
    dispatch(removeAnswer({ questionId: props.questionId }));
  const addTextAnswer = (textAnswer: string) =>
    dispatch(addAnswer({ questionId: props.questionId, textAnswer }));

  const handleTextFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (answer === undefined && event.target.value !== '') {
      addTextAnswer(event.target.value);
    }
    if (event.target.value === '') {
      removeTextAnswer();
      return;
    }
    updateAnswer(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="回答をこちらに入力してください。"
      value={answer}
      sx={{ marginTop: '0.5em', whiteSpace: 'pre-line' }}
      onChange={handleTextFormChange}
      error={isError}
      multiline={props.isLongText}
      minRows={props.isLongText ? 3 : undefined}
      helperText={
        props.disabled
          ? ''
          : `${
              isEmptyError
                ? 'この質問は回答必須です。回答を入力してください。\n'
                : ''
            }${
              generateTextAnswerRangeErrorMessage(props.ranges) +
              ' (現在' +
              answer.length +
              '文字)'
            }`
      }
      disabled={props.disabled}
    />
  );
};

export default TextForm;
