import { ChangeEvent, Dispatch, SetStateAction, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import ClearIcon from '@mui/icons-material/Clear';
import RestoreIcon from '@mui/icons-material/Restore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { EditingQuestion, EditingQuestionItem } from '../../interface/Question';
import { useDispatch } from 'react-redux';
import {
  deleteQuestionItem,
  restoreQuestionItem,
  switchItemOrder,
  updateQuestionItem
} from '../../redux/slice/QuestionnaireSlice';
import { isItemDeleted } from '../../common/manageQuestion';
import { useSelector } from '../../redux/store';

type EditableQuestionItemProps = {
  item: EditingQuestionItem;
  index: number;
  existEmptyItem: boolean;
  existQuestionsWithAllItemsDeleted: boolean;
  setEditableIndex: Dispatch<SetStateAction<number>>;
};

const EditableQuestionItem: React.FC<EditableQuestionItemProps> = (props) => {
  const {
    item,
    index,
    existEmptyItem,
    existQuestionsWithAllItemsDeleted,
    setEditableIndex
  } = props;

  const dispatch = useDispatch();

  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const items: EditingQuestionItem[] = question.items!;

  const isDeleted: boolean = 'isDeleted' in item && item.isDeleted;

  const handleDeleteItem = (itemIndex: number) => () => {
    setEditableIndex(items.length - 1);
    return dispatch(deleteQuestionItem(itemIndex));
  };
  const handleRestoreItem = (itemIndex: number) => () => {
    const deletedItems: EditingQuestionItem[] = items
      .toSpliced(itemIndex, 1)
      .filter(isItemDeleted);
    setEditableIndex(items.length - 1 - deletedItems.length);
    return dispatch(restoreQuestionItem(itemIndex));
  };
  const handleClickItemOrderButton = (itemIndex: number, isUpward: boolean) => {
    if (isUpward) {
      setEditableIndex(itemIndex - 1);
    } else {
      setEditableIndex(itemIndex + 1);
    }
    handleSwitchItemOrder(itemIndex, isUpward)();
  };
  const handleSwitchItemOrder = (itemIndex: number, isUpward: boolean) => () =>
    dispatch(switchItemOrder({ itemIndex, isUpward }));

  const updateItemName = (itemIndex: number, value: string) =>
    dispatch(updateQuestionItem({ key: 'name', value, itemIndex }));
  const switchIsDescription =
    (itemIndex: number) =>
    (event: SyntheticEvent<Element, Event>, checked: boolean) =>
      dispatch(
        updateQuestionItem({ key: 'isDescription', value: checked, itemIndex })
      );

  const isSomeRadioItemDescribable = (item: EditingQuestionItem): boolean =>
    question.type === 'radio' &&
    !item.isDescription &&
    items.some((item: EditingQuestionItem) => item.isDescription);

  return (
    <FormGroup
      row
      aria-label={`option${editingIndex + 1}-${index + 1}`}
      sx={{ marginBottom: '0.5em' }}
    >
      <TextField
        variant="standard"
        InputProps={{
          endAdornment: !isDeleted ? (
            <ButtonWithToolTip
              className="delete-question-item-button"
              title="削除"
              icon={<ClearIcon />}
              onClick={handleDeleteItem(index)}
              disabled={isDeleted}
            />
          ) : (
            <ButtonWithToolTip
              title="復元"
              icon={<RestoreIcon />}
              onClick={handleRestoreItem(index)}
            />
          )
        }}
        value={`${item.name}${isDeleted ? '(削除済み)' : ''}`}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          updateItemName(index, event.target.value);
        }}
        disabled={isDeleted}
        sx={{ marginTop: '0.5em' }}
        aria-label="option-text-field"
        error={existEmptyItem || existQuestionsWithAllItemsDeleted}
        className="item-name-input"
      />
      {(question.type === 'check' || question.type === 'radio') && (
        <FormControlLabel
          control={
            <Switch
              disabled={isDeleted || isSomeRadioItemDescribable(item)}
              className="description-switch"
            />
          }
          label="記述式"
          onChange={switchIsDescription(index)}
          checked={item.isDescription}
        />
      )}
      <ButtonWithToolTip
        title="上へ"
        icon={<ArrowUpwardIcon />}
        onClick={() => handleClickItemOrderButton(index, true)}
        disabled={index === 0 || isDeleted}
      />
      <ButtonWithToolTip
        title="下へ"
        icon={<ArrowDownwardIcon />}
        onClick={() => handleClickItemOrderButton(index, false)}
        disabled={index === items.length - 1 || isDeleted}
      />
    </FormGroup>
  );
};

export default EditableQuestionItem;
