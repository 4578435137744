import { Dispatch, SetStateAction } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';

type UserAuthoritiesProps = {
  questionnaires: QuestionnaireMetaData[];
  checkedIds: number[];
  setCheckedIds: Dispatch<SetStateAction<number[]>>;
};

const UserAuthorities: React.FC<UserAuthoritiesProps> = (props) => {
  return (
    <FormGroup sx={{ marginLeft: '0.5em' }}>
      {props.questionnaires.map((questionnaire: QuestionnaireMetaData) => (
        <FormControlLabel
          control={<Checkbox />}
          label={questionnaire.name}
          value={questionnaire.id}
          key={questionnaire.id}
          checked={props.checkedIds.includes(questionnaire.id)}
          onChange={() => {
            if (props.checkedIds.includes(questionnaire.id)) {
              props.setCheckedIds(
                props.checkedIds.filter((id: number) => id !== questionnaire.id)
              );
              return;
            }

            props.setCheckedIds([...props.checkedIds, questionnaire.id]);
          }}
        />
      ))}
    </FormGroup>
  );
};

export default UserAuthorities;
