import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import {
  ListUserResponse,
  User,
  UserPaginationToken
} from '../../interface/User';
import { listUsers } from '../../common/user';
import UserPaginationButtons from '../common/UserPaginationButtons';

type UserTableProps = {
  users: User[];
  paginationTokens: UserPaginationToken;
  targetUserName: string;
  setUsers: Dispatch<SetStateAction<User[]>>;
  setPaginationTokens: Dispatch<SetStateAction<UserPaginationToken>>;
};

const UserTable: React.FC<UserTableProps> = (props) => {
  const navigate = useNavigate();

  const handlePrev = async () => {
    const paginationToken: string =
      props.paginationTokens.prev![props.paginationTokens.prev!.length - 1];
    const response: ListUserResponse = await listUsers(
      props.targetUserName,
      paginationToken
    );
    props.setUsers(response.users);

    const newPrev: string[] = props.paginationTokens.prev!.slice(
      0,
      props.paginationTokens.prev!.length
    );
    props.setPaginationTokens({
      prev: newPrev.length === 0 ? undefined : newPrev,
      present: paginationToken,
      next: response.paginationToken
    });
  };

  const handleNext = async () => {
    const response: ListUserResponse = await listUsers(
      props.targetUserName,
      props.paginationTokens.next
    );
    props.setUsers(response.users);

    const prevPaginationTokens: string[] =
      props.paginationTokens.prev === undefined
        ? []
        : props.paginationTokens.prev;
    props.setPaginationTokens({
      prev: [...prevPaginationTokens, props.paginationTokens.present],
      present: props.paginationTokens.next!,
      next: response.paginationToken
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ユーザー名</TableCell>
            <TableCell>権限</TableCell>
            <TableCell>メールアドレス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.map((user) => (
            <TableRow
              key={user.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                ':hover': {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
              onClick={() => {
                navigate(`/user-management/user`, {
                  state: { id: user.id }
                });
              }}
            >
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell sx={{ textAlign: 'right', alignItems: 'right' }}>
              <UserPaginationButtons
                handlePrev={handlePrev}
                handleNext={handleNext}
                paginationTokens={props.paginationTokens}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
