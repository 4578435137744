import { useEffect } from 'react';
import {
  AddedQuestion,
  AddedQuestionItem,
  AnswerRangeForApi,
  QuestionCondition,
  QuestionConditionForAPI,
  EditingAnswerRange,
  NewQuestion,
  NewQuestionItem,
  QuestionGroup,
  EditingQuestion
} from '../../interface/Question';
import EditableQuestionnaire from './EditableQuestionnaire';
import { Auth } from 'aws-amplify';
import { NewGroup, Questionnaire } from '../../interface/Questionnaire';
import { Inheritance } from '../../interface/Inheritance';
import { postQuestionnaire } from '../../api';
import { useDispatch } from 'react-redux';
import { clearState } from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import { createInheritance } from '../../common/manageQuestion';

const createNewGroups = (groups: QuestionGroup[]): NewGroup[] =>
  groups.map((group: QuestionGroup) => ({ name: group.name }));

const createQuestionnaire = async (
  questionnaireName: string,
  questions: AddedQuestion[],
  isPublic: boolean,
  inheritance?: Inheritance,
  groups?: NewGroup[]
): Promise<Questionnaire> => {
  const user = await Auth.currentAuthenticatedUser();

  return {
    userId: user.attributes.email,
    name: questionnaireName,
    inheritance,
    isPublic,
    questions,
    groups
  };
};

const FormCreatePage: React.FC = () => {
  const dispatch = useDispatch();
  const clear = () => dispatch(clearState());

  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );

  const save = async () => {
    const saveTarget: NewQuestion[] =
      editingIndex === -1
        ? questions
        : questions.map((question: NewQuestion, index: number) => {
            if (index === editingIndex) return editingQuestion;

            return question;
          });

    const formedSaveTarget: AddedQuestion[] = saveTarget.map(
      (question: NewQuestion, i: number, original: NewQuestion[]) => {
        const items: AddedQuestionItem[] | undefined =
          question.items === undefined
            ? undefined
            : question.items.map((item: NewQuestionItem, index: number) => ({
                ...item,
                priority: i + 1
              }));

        const ranges: AnswerRangeForApi[] | undefined =
          question.ranges === undefined
            ? undefined
            : question.ranges.map((range: EditingAnswerRange) => ({
                min: Number(range.minValue),
                max: Number(range.maxValue),
                includesBoundaryValue: range.includesBoundaryValue
              }));

        const questionConditions: QuestionConditionForAPI[] | undefined =
          question.questionConditions === undefined
            ? undefined
            : question.questionConditions.map(
                (condition: QuestionCondition) => ({
                  id: condition.id,
                  questionItemIndex: question.items!.findIndex(
                    (item: NewQuestionItem) =>
                      item.id === condition.questionItemId
                  ),
                  childQuestionIndex:
                    condition.childQuestionId === undefined
                      ? undefined
                      : original.findIndex(
                          (target: NewQuestion) =>
                            target.id === condition.childQuestionId
                        ),
                  childGroupIndex:
                    condition.childGroupId === undefined
                      ? undefined
                      : groups!.findIndex(
                          (group: QuestionGroup) =>
                            group.id === condition.childGroupId
                        )
                })
              );

        const extractGroupIndex =
          question.groupId === undefined
            ? undefined
            : Math.abs(question.groupId!);

        return {
          question: question.question,
          type: question.type,
          required: question.required,
          headline: question.headline,
          items,
          ranges,
          questionConditions,
          canInherit: question.canInherit,
          priority: i + 1,
          groupIndex: extractGroupIndex
        };
      }
    );

    const areSomeQuestionsCanInherit: boolean = questions.some(
      (question: EditingQuestion, i: number) => {
        if (i === editingIndex) return editingQuestion.canInherit;
        return question.canInherit;
      }
    );
    const newInheritance: Inheritance | undefined = !areSomeQuestionsCanInherit
      ? undefined
      : createInheritance(
          inheritance.isSameUser,
          inheritance.questionId!,
          inheritance.questionId === undefined
            ? undefined
            : questions[inheritance.questionId]
        );

    const newQuestionnaire: Questionnaire = await createQuestionnaire(
      questionnaireName,
      formedSaveTarget,
      isPublic,
      newInheritance,
      groups === undefined || groups.length === 0
        ? undefined
        : createNewGroups(groups)
    );
    await postQuestionnaire(newQuestionnaire);
  };

  useEffect(() => {
    clear();
  }, []);
  return <EditableQuestionnaire save={save} />;
};

export default FormCreatePage;
