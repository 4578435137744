import { KeyboardEvent, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { FilterCondition } from '../../interface/AnswersTable';
import { useSelector } from '../../redux/store';
import { Inheritance } from '../../interface/Inheritance';

type QuoteTargetFilterTextFieldProps = {
  filters: FilterCondition[];
  addFilter: (filter: FilterCondition) => void;
  removeFilter: (index: number) => void;
};

const QuoteTargetFilterTextField: React.FC<QuoteTargetFilterTextFieldProps> = (
  props
) => {
  const DEFAULT_OPTIONS: string[] = ['キー項目', '回答者氏名'];
  const { filters, addFilter, removeFilter } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const inheritance: Inheritance = useSelector(
    (state) => state.answer.inheritance
  )!;

  return (
    <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
      <Autocomplete
        options={
          DEFAULT_OPTIONS.some((option) => inputValue.startsWith(option))
            ? []
            : DEFAULT_OPTIONS
        }
        id="answer-filter-text-field"
        freeSolo
        disableClearable
        value={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event: any, newValue: string | null) => {
          if (newValue === null) return;
          setInputValue(`${newValue}:`);
        }}
        onKeyDown={(
          event: KeyboardEvent<HTMLDivElement> & {
            defaultMuiPrevented?: boolean | undefined;
          }
        ) => {
          const isOnlyQueryKey: boolean =
            inputValue === 'キー項目:' || inputValue === '回答者氏名:';
          const isQueryKeySubString: boolean = DEFAULT_OPTIONS.some(
            (option: string) => option.indexOf(inputValue) > -1
          );
          if (
            event.key === 'Enter' &&
            !event.defaultMuiPrevented &&
            !isOnlyQueryKey &&
            !isQueryKeySubString &&
            inputValue !== ''
          ) {
            addFilter({
              filterTarget: inputValue.startsWith('回答者氏名:')
                ? 0
                : inheritance.questionId!,
              condition: 'like',
              value: inputValue.startsWith('回答者氏名:')
                ? inputValue.replace('回答者氏名:', '')
                : inputValue.replace('キー項目:', '')
            });
            setInputValue('');
            (document.activeElement! as HTMLInputElement).blur();
          }
        }}
        slotProps={{}}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              input: { ...params.InputProps, startAdornment: <SearchIcon /> }
            }}
          />
        )}
      />
      <Stack
        direction="row"
        spacing={1}
        useFlexGap
        sx={{ marginTop: '0.5em', maxWidth: '100%', flexWrap: 'wrap' }}
      >
        {filters.map((filter: FilterCondition, index: number) => (
          <Chip
            key={index}
            label={
              filter.filterTarget === 0
                ? `回答者氏名:${filter.value}`
                : `キー項目:${filter.value}`
            }
            onDelete={() => {
              removeFilter(index);
            }}
            variant="outlined"
          />
        ))}
      </Stack>
    </Box>
  );
};

export default QuoteTargetFilterTextField;
