import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import TopPage from './components/top/TopPage';
import FormManagementTopPage from './components/form-management/FormManagementTopPage';
import FormCreatePage from './components/form-management/FormCreatePage';
import FormEditPage from './components/form-management/FormEditPage';
import AnswersTableTopPage from './components/answers-table/AnswersTableTopPage';
import AnswersTable from './components/answers-table/AnswersTable';
import AnswerPage from './components/form-answer/AnswerPage';
import FileIOTopPage from './components/file-io/FileIOTopPage';
import CSVUploadPage from './components/file-io/CSVUploadPage';
import NotificationRegisterPage from './components/notification-register/NotificationRegisterPage';
import NotificationEditPage from './components/notification-register/NotificationEditPage';
import NotificationEditTop from './components/notification-register/NotificationEditTop';
import UserManagementTopPage from './components/user-management/UserManagementTopPage';
import FormAuthorityManagementPage from './components/form-management/FormAuthorityManagementPage';
import UserInformationPage from './components/user-management/UserInformationPage';
import { PagePath } from './interface/Page';
import { store } from './redux/store';

const pagePaths: { [name: string]: PagePath } = {
  top: { name: 'トップ', path: '/' },
  formManagement: { name: 'フォーム管理', path: '/form-management' },
  formAnswer: { name: 'フォーム回答', path: '/form-answer/:hash' },
  formCreation: { name: '新規フォーム作成', path: '/form-management/new' },
  formEdit: { name: 'フォーム編集', path: '/form-edit/:questionnaireId' },
  formAuthorityManagement: {
    name: 'フォーム権限管理',
    path: '/form-authority/:questionnaireId'
  },
  formList: { name: 'フォーム回答一覧', path: '/form-answers-table' },
  answerList: {
    name: '回答一覧',
    path: '/form-answers-table/:questionnaireId'
  },
  fileIO: { name: 'ファイル入出力', path: '/file-io' },
  csvUpload: { name: 'CSVアップロード', path: '/csv-upload' },
  notificationCreation: {
    name: 'お知らせ新規登録',
    path: '/notification-register'
  },
  notificationEditTop: {
    name: 'お知らせ編集トップ',
    path: '/notification-edit'
  },
  notificationEdit: {
    name: 'お知らせ編集',
    path: '/notification-edit/:notificationId'
  },
  userManagement: { name: 'ユーザー管理', path: '/user-management' },
  userDetails: {
    name: 'ユーザー詳細情報',
    path: '/user-management/:userId'
  }
};

const router = createBrowserRouter([
  {
    path: pagePaths.top.path,
    element: <App component={<TopPage />} pagePaths={[pagePaths.top]} />
  },
  {
    path: pagePaths.formManagement.path,
    element: (
      <App
        component={<FormManagementTopPage />}
        pagePaths={[pagePaths.top, pagePaths.formManagement]}
      />
    )
  },
  {
    path: pagePaths.formAnswer.path,
    element: (
      <App
        component={<AnswerPage />}
        pagePaths={[pagePaths.top, pagePaths.formAnswer]}
      />
    )
  },
  {
    path: pagePaths.formCreation.path,
    element: (
      <App
        component={<FormCreatePage />}
        pagePaths={[
          pagePaths.top,
          pagePaths.formManagement,
          pagePaths.formCreation
        ]}
      />
    )
  },
  {
    path: pagePaths.formEdit.path,
    element: (
      <App
        component={<FormEditPage />}
        pagePaths={[
          pagePaths.top,
          pagePaths.formManagement,
          pagePaths.formEdit
        ]}
      />
    )
  },
  {
    path: pagePaths.formAuthorityManagement.path,
    element: (
      <App
        component={<FormAuthorityManagementPage />}
        pagePaths={[
          pagePaths.top,
          pagePaths.formManagement,
          pagePaths.formAuthorityManagement
        ]}
      />
    )
  },
  {
    path: pagePaths.formList.path,
    element: (
      <App
        component={<AnswersTableTopPage />}
        pagePaths={[pagePaths.top, pagePaths.formList]}
      />
    )
  },
  {
    path: pagePaths.answerList.path,
    element: (
      <App
        component={<AnswersTable />}
        pagePaths={[pagePaths.top, pagePaths.formList, pagePaths.answerList]}
      />
    )
  },
  {
    path: pagePaths.fileIO.path,
    element: (
      <App
        component={<FileIOTopPage />}
        pagePaths={[pagePaths.top, pagePaths.fileIO]}
      />
    )
  },
  {
    path: pagePaths.csvUpload.path,
    element: (
      <App
        component={<CSVUploadPage />}
        pagePaths={[pagePaths.top, pagePaths.fileIO, pagePaths.csvUpload]}
      />
    )
  },
  {
    path: pagePaths.notificationCreation.path,
    element: (
      <App
        component={<NotificationRegisterPage />}
        pagePaths={[pagePaths.top, pagePaths.notificationCreation]}
      />
    )
  },
  {
    path: pagePaths.notificationEditTop.path,
    element: (
      <App
        component={<NotificationEditTop />}
        pagePaths={[pagePaths.top, pagePaths.notificationEditTop]}
      />
    )
  },
  {
    path: pagePaths.notificationEdit.path,
    element: (
      <App
        component={<NotificationEditPage />}
        pagePaths={[
          pagePaths.top,
          pagePaths.notificationEditTop,
          pagePaths.notificationEdit
        ]}
      />
    )
  },
  {
    path: pagePaths.userManagement.path,
    element: (
      <App
        component={<UserManagementTopPage />}
        pagePaths={[pagePaths.top, pagePaths.userManagement]}
      />
    )
  },
  {
    path: pagePaths.userDetails.path,
    element: (
      <App
        component={<UserInformationPage />}
        pagePaths={[
          pagePaths.top,
          pagePaths.userManagement,
          pagePaths.userDetails
        ]}
      />
    )
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
