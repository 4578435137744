import { configureStore } from '@reduxjs/toolkit';
import questionnaireSlice from './slice/QuestionnaireSlice';
import answerSlice from './slice/AnswerSlice';
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook
} from 'react-redux';

export const store = configureStore({
  reducer: {
    questionnaire: questionnaireSlice,
    answer: answerSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
