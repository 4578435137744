import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  AnswerPerQuestion,
  AnswersTableHeader,
  AnswersTableMetaData,
  AnswersTableResponse,
  FilterCondition,
  HeaderItems,
  SortCondition
} from '../../interface/AnswersTable';
import { fetchAuthorizedUsers, fetchFilteredAnswers } from '../../api/index';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import FilterModal from './FilterModal';
import { ExistingQuestion } from '../../interface/Question';
import { User } from '../../interface/User';
import {
  createNeatFilterConditions,
  getFilterConditionKey
} from '../../common/filter';

type TableToolbarProps = {
  questionnaireName: String;
  questionnaireId: number;
  headers: AnswersTableHeader[];
  totalCount: number;
  selected: readonly number[];
  filterConditions: FilterCondition[];
  sortConditions: SortCondition[];
  questions: ExistingQuestion[];
  isUnique: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setFilterConditions: Dispatch<SetStateAction<FilterCondition[]>>;
  setSortConditions: Dispatch<SetStateAction<SortCondition[]>>;
  setIsUnique: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
  fetchAnswers: Function;
};

const TableToolbar: React.FC<TableToolbarProps> = (props) => {
  const [action, setAction] = useState<string>('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [isFormOwner, setIsFormOwner] = useState<boolean>(false);

  const questionnaireName = props.questionnaireName;
  const questionnaireId: number = props.questionnaireId;
  const headers = props.headers;
  const totalCount = props.totalCount;
  const navigate = useNavigate();
  const isUnique = props.isUnique;
  const filterConditions = props.filterConditions;
  const sortConditions = props.sortConditions;
  const location = useLocation();
  const hash: string = location.pathname.split('/')[2];

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.email;
      if (user.attributes['custom:role'] === '管理者') {
        setIsFormOwner(true);
        return;
      }

      if (questionnaireId === -1) return;
      const authorizedUsers: User[] = await fetchAuthorizedUsers(
        questionnaireId
      );

      if (authorizedUsers.some((user: User) => user.email === userId))
        setIsFormOwner(true);
    })();
  }, [questionnaireId]);

  const handleChange = (event: SelectChangeEvent) => {
    const isAnswerTablePage: boolean | null = true;

    setAction(event.target.value);
    if (event.target.value === 'editAnswer') {
      navigate(`/form-answer/${hash}`, {
        state: {
          answerId: props.selected[0],
          fromAnswerTablePage: isAnswerTablePage
        }
      });
    }
  };

  const downloadCSV = (csvData: string, fileName: string) => {
    const bom: Uint8Array = new Uint8Array([0xef, 0xbbb, 0xbf]);
    const blob: Blob = new Blob([bom, csvData], { type: 'text/csv' });
    const objectUrl: string = URL.createObjectURL(blob);
    const downloadLink: HTMLAnchorElement = document.createElement('a');

    downloadLink.download = fileName;
    downloadLink.href = objectUrl;
    downloadLink.click();
    downloadLink.remove();
  };

  const padZero = (num: number): string => `${('0' + num).slice(-2)}`;

  const formatTimestamp = (date: Date): string => {
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
      date.getDate()
    )}-${padZero(date.getHours())}-${padZero(date.getMinutes())}-${padZero(
      date.getSeconds()
    )}`;
  };

  const createFileName = (): string => {
    return `${questionnaireName}_${formatTimestamp(new Date())}.csv`;
  };

  const formatStringForCSV = (str?: string): string => {
    if (str === undefined) return '';
    return `"${str.replace(/"/g, '""')}"`;
  };

  const headersWithItems: AnswersTableHeader[] = headers.filter(
    (header) => 'items' in header
  );

  const formedHeadersWithItems: AnswersTableHeader[] = headersWithItems
    .map((header: AnswersTableHeader) => {
      return header.items!.map((item: HeaderItems) => {
        return {
          id: header.id,
          itemId: item.id,
          name: `${header.name}_${item.name}`
        };
      });
    })
    .flat();

  const formHeadersHaveSameIds = (
    id: number,
    index: number
  ): AnswersTableHeader[] => {
    return formedHeadersWithItems
      .filter((header: AnswersTableHeader) => header.id === id)
      .map((header: AnswersTableHeader) => ({
        ...header,
        questionIndex: index
      }));
  };

  const formHeaders = (): AnswersTableHeader[] =>
    headers.flatMap((header: AnswersTableHeader, index: number) => {
      if (!('items' in header)) {
        return {
          id: header.id,
          name: header.name,
          questionIndex: index
        };
      }
      return [...formHeadersHaveSameIds(header.id, index)];
    });

  const convertHeadersToCSV = (formedHeaders: AnswersTableHeader[]): string => {
    const headersToCSV: string = formedHeaders
      .map((header: AnswersTableHeader) =>
        formatStringForCSV(`[Q${header.questionIndex! + 1}] ${header.name}`)
      )
      .join(',');
    return `ID,${headersToCSV},回答者,回答日,更新者,更新日\r\n`;
  };

  const formAnswers = (
    metaData: AnswersTableMetaData[],
    formedHeaders: AnswersTableHeader[]
  ): AnswerPerQuestion[][] => {
    return metaData.map((answers: AnswersTableMetaData) => {
      return formedHeaders.map((header: AnswersTableHeader) => {
        const indexEqualId = answers.answer.findIndex(
          (answer) => answer.id === header.id && answer.itemId === header.itemId
        );
        const indexEqualItemId = answers.answer.findIndex(
          (answer) => answer.itemId === header.itemId
        );

        if (indexEqualId === -1) {
          //headerのquestionIdに対応するanswerがない場合
          return {
            id: header.id,
            itemName: ''
          };
        }
        if (!('itemId' in answers.answer[indexEqualId])) {
          //answerがitemIdを持っていない場合
          return {
            id: header.id,
            itemName: answers.answer[indexEqualId].textAnswer
              ? answers.answer[indexEqualId].textAnswer
              : answers.answer[indexEqualId].itemName
          };
        }
        if (indexEqualItemId === -1) {
          //answerがitemIdを持っているが、対応するItemIdでない場合
          return {
            id: header.id,
            itemName: ''
          };
        }
        if (answers.answer[indexEqualId].textAnswer) {
          //answerがitemIdを持っており、記述形式の回答を持っている場合
          return {
            id: header.id,
            itemName: answers.answer[indexEqualId].textAnswer
          };
        }
        return {
          //answerがitemIdを持っており、対応するItemIdの場合
          id: header.id,
          itemName: '1'
        };
      });
    });
  };

  const convertAnswersToCSV = (
    metaData: AnswersTableMetaData[],
    formedAnswers: AnswerPerQuestion[][]
  ): string => {
    return metaData
      .map((answers: AnswersTableMetaData, index: number) => {
        return `${answers.metadataId},${formedAnswers[index]
          .map((answer: AnswerPerQuestion) =>
            formatStringForCSV(answer.itemName)
          )
          .join(',')},${answers.userId},${answers.answeredDate},${
          answers.updateUser === null ? '' : answers.updateUser
        },${answers.updatedDate}\r\n`;
      })
      .join('');
  };

  const createAllDownloadCSVData = (
    metaData: AnswersTableMetaData[]
  ): string => {
    const formedHeaders: AnswersTableHeader[] = formHeaders();
    const formedAnswers: AnswerPerQuestion[][] = formAnswers(
      metaData,
      formedHeaders
    );
    return (
      convertHeadersToCSV(formedHeaders) +
      convertAnswersToCSV(metaData, formedAnswers)
    );
  };

  const handleSelectAllDownload = async (): Promise<void> => {
    // 1000件以上の場合はなにもしない
    if (totalCount > 1000) return;

    const neatFilterConditions: FilterCondition[] =
      createNeatFilterConditions(filterConditions);

    // APIを叩く回数
    const fetchCount: number = Math.trunc(totalCount / 100) + 1;
    const response: AnswersTableResponse = (
      await Promise.all(
        [...Array(fetchCount)].map(async (_, i: number) => {
          const limit: number = i === fetchCount - 1 ? totalCount % 100 : 100;

          return await fetchFilteredAnswers(
            questionnaireId,
            isUnique,
            limit,
            i * 100,
            neatFilterConditions,
            sortConditions
          );
        })
      )
    ).reduce(
      (
        allData: AnswersTableResponse,
        dividedResponse: AnswersTableResponse
      ) => ({
        headers: dividedResponse.headers,
        totalCount: dividedResponse.totalCount,
        answers: [...allData.answers, ...dividedResponse.answers]
      }),
      { headers: [], answers: [], totalCount: 0 }
    );

    const metaData: AnswersTableMetaData[] = response.answers;
    const fileName: string = createFileName();
    const csvData: string = createAllDownloadCSVData(metaData);
    downloadCSV(csvData, fileName);
  };

  const setFilterConditionsInSessionStorage = () => {
    const filterConditionKeyName: string = getFilterConditionKey(hash);
    const filterConditionsString: string = JSON.stringify(
      props.filterConditions
    );
    sessionStorage.setItem(filterConditionKeyName, filterConditionsString);
  };

  const handleSelectDelete = async (): Promise<void> => {
    props.setIsModalOpen(true);
  };

  const handleSelectDisplayHistory = async (): Promise<void> => {
    props.setIsUnique(false);
  };

  const handleSelectDisplayNew = async (): Promise<void> => {
    props.setIsUnique(true);
  };

  const handleSelectDownloadQuestions = (): void => {
    const fileName: string = `${questionnaireName}_質問文.txt`;
    const content: string = props.questions
      .filter((question: ExistingQuestion) => question.type !== 'message')
      .map(
        (question: ExistingQuestion, index: number) =>
          `[Q${index + 1}] ${question.headline}\n${question.question}`
      )
      .join('\n\n');
    const bom: Uint8Array = new Uint8Array([0xef, 0xbbb, 0xbf]);
    const blob: Blob = new Blob([bom, content], { type: 'text/plain' });
    const objectUrl: string = URL.createObjectURL(blob);
    const downloadLink: HTMLAnchorElement = document.createElement('a');

    downloadLink.download = fileName;
    downloadLink.href = objectUrl;
    downloadLink.click();
    downloadLink.remove();
  };

  const existFilterConditions = () => {
    const neatFilterConditions: FilterCondition[] =
      createNeatFilterConditions(filterConditions);
    return neatFilterConditions.length !== 0;
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          対象フォーム：{questionnaireName}
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
          <InputLabel id="select-small-label">アクション</InputLabel>
          <Select
            id="action-select"
            value={action}
            label="アクション"
            onChange={handleChange}
          >
            <MenuItem
              value="allDownload"
              onClick={handleSelectAllDownload}
              className="action-download"
            >
              ダウンロード
            </MenuItem>
            <MenuItem
              value="downloadQuestions"
              onClick={handleSelectDownloadQuestions}
              className="action-download"
            >
              質問文をダウンロード
            </MenuItem>
            <MenuItem
              value="editAnswer"
              onClick={setFilterConditionsInSessionStorage}
              disabled={props.selected.length !== 1}
              className="action-answer-edit"
            >
              回答を編集
            </MenuItem>
            <MenuItem
              value="delete"
              disabled={props.selected.length < 1 || !isFormOwner}
              onClick={handleSelectDelete}
              className="action-answer-delete"
            >
              削除
            </MenuItem>
            <MenuItem
              value="displayHistory"
              onClick={handleSelectDisplayHistory}
              disabled={!props.isUnique}
              className="action-display-history"
            >
              履歴表示
            </MenuItem>
            <MenuItem
              value="displayNew"
              onClick={handleSelectDisplayNew}
              disabled={props.isUnique}
              className="action-display-new"
            >
              最新の回答
            </MenuItem>
          </Select>
        </FormControl>
        <ButtonWithToolTip
          title="フィルター"
          onClick={() => setIsFilterModalOpen(true)}
          icon={
            <FilterListIcon
              color={existFilterConditions() ? 'primary' : undefined}
            />
          }
          className="filter"
        />
      </Toolbar>
      <FilterModal
        isOpen={isFilterModalOpen}
        filterConditions={props.filterConditions}
        sortConditions={props.sortConditions}
        questions={props.questions}
        handleClose={() => setIsFilterModalOpen(false)}
        setFilterConditions={props.setFilterConditions}
        setSortConditions={props.setSortConditions}
        setPage={props.setPage}
        fetchAnswers={props.fetchAnswers}
      />
    </>
  );
};

export default TableToolbar;
