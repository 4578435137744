import {
  EditingQuestionOrGroup,
  GroupedEditingQuestion,
  QuestionGroup
} from '../interface/Question';

const getTailItem = <T>(array: T[]): T => array[array.length - 1];

export const updateNextTo = (
  groupedQuestions: EditingQuestionOrGroup[],
  groups: QuestionGroup[]
): QuestionGroup[] => {
  const updatedNextTo: EditingQuestionOrGroup[] = groupedQuestions.reduce(
    (
      accumulator: EditingQuestionOrGroup[],
      groupOrQuestion: EditingQuestionOrGroup,
      index: number
    ) => {
      if ('question' in groupOrQuestion)
        return [...accumulator, groupOrQuestion];

      const getPreviousGroupId = (
        previous: EditingQuestionOrGroup
      ): number | undefined => {
        if ('question' in previous) return undefined;

        return previous.groupId;
      };

      const getPreviousQuestionId = (
        previous: EditingQuestionOrGroup
      ): number | undefined => {
        if ('question' in previous) return previous.id;

        return previous.questions.length > 0
          ? getTailItem(previous.questions).id
          : previous.nextTo.questionId;
      };

      const previousQuestionId: number | undefined =
        index === 0 ? undefined : getPreviousQuestionId(accumulator[index - 1]);
      const previousGroupId: number | undefined =
        index === 0 ? undefined : getPreviousGroupId(accumulator[index - 1]);

      return [
        ...accumulator,
        {
          ...groupOrQuestion,
          nextTo: { questionId: previousQuestionId, groupId: previousGroupId }
        }
      ];
    },
    []
  );

  const updatedGroups: GroupedEditingQuestion[] = updatedNextTo.filter(
    (groupOrQuestion: EditingQuestionOrGroup) => 'groupName' in groupOrQuestion
  ) as GroupedEditingQuestion[];

  return groups.map((group: QuestionGroup) => {
    const targetGroup: GroupedEditingQuestion = updatedGroups.find(
      (groupedQuestion: GroupedEditingQuestion) =>
        groupedQuestion.groupId === group.id
    )!;

    return {
      id: targetGroup.groupId,
      name: targetGroup.groupName,
      nextTo: targetGroup.nextTo,
      isOpen: targetGroup.isOpen
    };
  });
};
