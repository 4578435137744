import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MessageCardAction from './MessageCardAction';
import { EditingQuestion } from '../../interface/Question';
import { FormHelperText } from '@mui/material';
import { ERROR_COLOR } from '../../common/color';
import { getQuestionIndexesHaveDuplicateHeadline } from '../../common/manageQuestion';

type MessageCardProps = {
  question: EditingQuestion;
  questions: EditingQuestion[];
  index: number;
  isBottom: boolean;
  isTop: boolean;
  isDeleted: boolean;
};

const MessageCard: React.FC<MessageCardProps> = (props) => {
  return (
    <Box className="message-card">
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary={
              <>
                <b>見出し（この見出しは回答画面では表示されません）: </b>
                {props.question.headline}
              </>
            }
          />
        </ListItem>
        {props.question.headline === '' ? (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            見出しを入力してください
          </FormHelperText>
        ) : getQuestionIndexesHaveDuplicateHeadline(
            props.question,
            props.questions
          ) ? (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            他の質問と見出しが重複しています（
            {getQuestionIndexesHaveDuplicateHeadline(
              props.question,
              props.questions
            )!.join()}
            ）
          </FormHelperText>
        ) : (
          ''
        )}
        <ListItem disablePadding>
          <ListItemText
            primary={
              <>
                <b>メッセージの本文: </b>
                {props.question.question}
              </>
            }
          />
        </ListItem>
        {props.question.question === '' && (
          <FormHelperText sx={{ color: ERROR_COLOR }}>
            メッセージの本文を入力してください
          </FormHelperText>
        )}
      </List>
      <Divider />
      <MessageCardAction
        index={props.index}
        isDeleted={props.isDeleted}
        isTop={props.isTop}
        isBottom={props.isBottom}
        isEditing={false}
      />
    </Box>
  );
};

export default MessageCard;
