import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Notification, NotificationType } from '../../interface/Notification';
import { fetchNotificationTypes } from '../../api';
import { getInvertedColor, HEX } from '../../common/color';

type NotificationTableProps = {
  notifications: Notification[];
  maxHeight?: number;
  onClickTableCell?: Function;
  onClickTableRow?: Function;
};

const NotificationTable: React.FC<NotificationTableProps> = (props) => {
  const onClickTableRow =
    props.onClickTableRow !== undefined
      ? props.onClickTableRow
      : (id: number) => () => {};
  const onClickTableCell =
    props.onClickTableCell !== undefined
      ? props.onClickTableCell
      : (id: number) => () => {};

  const [types, setTypes] = useState<NotificationType[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedTypes: NotificationType[] = await fetchNotificationTypes();
      setTypes(fetchedTypes);
    })();
  }, []);

  const getType = (
    types: NotificationType[],
    typeId: number
  ): NotificationType => {
    const targetType: NotificationType | undefined = types.find(
      (notificationType: NotificationType) => notificationType.id === typeId
    );

    if (targetType === undefined) return { name: '', id: 0, color: '#ffffff' };
    return targetType;
  };

  return (
    <TableContainer sx={{ maxHeight: props.maxHeight }}>
      <Table stickyHeader sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">掲載日</TableCell>
            <TableCell align="center">種別</TableCell>
            <TableCell align="left">件名</TableCell>
            <TableCell align="left">登録者</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: 'scroll', height: '100px' }}>
          {props.notifications.map((notification) => {
            const notificationType: NotificationType = getType(
              types,
              notification.typeId
            );
            const typeFontColor: string = getInvertedColor(
              notificationType.color as HEX
            );
            return (
              <TableRow
                key={notification.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ':hover':
                    props.onClickTableRow !== undefined
                      ? { backgroundColor: '#F3F6F9', cursor: 'pointer' }
                      : {}
                }}
                onClick={onClickTableRow(notification.id)}
              >
                <TableCell>{notification.publishDate}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      backgroundColor: notificationType.color,
                      color: typeFontColor,
                      width: '3.5em',
                      height: '1.5em',
                      margin: '0 auto'
                    }}
                  >
                    {notificationType.name}
                  </Box>
                </TableCell>
                <TableCell>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={onClickTableCell(notification.id)}
                  >
                    {notification.title}
                  </Link>
                </TableCell>
                <TableCell>{notification.userId}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationTable;
