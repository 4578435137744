import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { PagePath } from '../../interface/Page';

type BreadcrumbsProps = {
  paths: PagePath[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '0.5em' }}>
      {props.paths.slice(0, -1).map((pagePath: PagePath) => (
        <Link
          underline="hover"
          color="inherit"
          href={pagePath.path}
          key={pagePath.name}
        >
          {pagePath.name}
        </Link>
      ))}
      <Typography color="text.primary">
        {props.paths[props.paths.length - 1].name}
      </Typography>
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
