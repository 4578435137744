import { Dispatch, SetStateAction } from 'react';
import { EditingQuestionItem } from '../../interface/Question';
import EditableQuestionItem from './EditableQuestionItem';
import UneditableQuestionItem from './UneditableQuestionItem';

type QuestionItemProps = {
  index: number;
  item: EditingQuestionItem;
  editableIndex: number;
  existEmptyItem: boolean;
  existQuestionsWithAllItemsDeleted: boolean;
  setEditableIndex: Dispatch<SetStateAction<number>>;
};

const QuestionItem: React.FC<QuestionItemProps> = (props) => {
  const {
    index,
    item,
    editableIndex,
    existEmptyItem,
    existQuestionsWithAllItemsDeleted,
    setEditableIndex
  } = props;

  return index !== editableIndex ? (
    <UneditableQuestionItem
      item={item}
      onClick={() => {
        setEditableIndex(index);
      }}
    />
  ) : (
    <EditableQuestionItem
      item={item}
      index={index}
      setEditableIndex={setEditableIndex}
      existEmptyItem={existEmptyItem}
      existQuestionsWithAllItemsDeleted={existQuestionsWithAllItemsDeleted}
    />
  );
};

export default QuestionItem;
