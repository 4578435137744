import { MouseEventHandler } from 'react';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { SortCondition } from '../../interface/AnswersTable';
import { ExistingQuestion } from '../../interface/Question';

type SortFormsProps = {
  sortCondition: SortCondition;
  sortConditions: SortCondition[];
  questions: ExistingQuestion[];
  changeSortTarget: (event: SelectChangeEvent<any>) => void;
  changeIsAsc: (event: SelectChangeEvent<any>) => void;
  removeSort: MouseEventHandler<HTMLButtonElement>;
};

const SortForms: React.FC<SortFormsProps> = (props) => {
  return (
    <Stack spacing={2} direction="row" sx={{ marginTop: '1.75em' }}>
      <FormControl sx={{ width: '40%' }}>
        <InputLabel>項目</InputLabel>
        <Select
          value={props.sortCondition.sortTarget}
          label="項目"
          onChange={props.changeSortTarget}
          aria-label="sort-column"
        >
          <MenuItem value="0">ID</MenuItem>
          {props.questions
            .filter(
              (question: ExistingQuestion) =>
                question.type !== 'check' &&
                question.type !== 'message' &&
                props.sortConditions.every(
                  (condition: SortCondition) =>
                    condition.sortTarget !== question.id
                )
            )
            .map((question: ExistingQuestion) => (
              <MenuItem key={question.id} value={question.id}>
                {question.headline}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: '25%' }}>
        <InputLabel>順序</InputLabel>
        <Select
          value={String(props.sortCondition.isAsc)}
          label="順序"
          onChange={props.changeIsAsc}
          aria-label="sort-order"
        >
          <MenuItem value="true">昇順</MenuItem>
          <MenuItem value="false">降順</MenuItem>
        </Select>
      </FormControl>
      <ButtonWithToolTip
        title="ソート列削除"
        onClick={props.removeSort}
        icon={<CancelIcon />}
        className="sort-remove"
      />
    </Stack>
  );
};

export default SortForms;
