import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { fetchQuestionnaires } from '../../api';
import {
  QuestionnaireGetResponse,
  QuestionnaireMetaData
} from '../../interface/Questionnaire';

type TargetFormSelectProps = {
  questionnaireId: string;
  onChange: (event: SelectChangeEvent<any>) => void;
};

const TargetFormSelect: React.FC<TargetFormSelectProps> = (props) => {
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireMetaData[]>(
    [
      {
        id: 0,
        name: '',
        userId: '',
        createdDate: '',
        updatedDate: '',
        isDeleted: false,
        hash: ''
      }
    ]
  );

  useEffect(() => {
    (async () => {
      let offset: number = 0;
      while (1) {
        const response: QuestionnaireGetResponse = await fetchQuestionnaires(
          100,
          offset
        );
        offset += response.questionnaires.length;
        setQuestionnaires([...questionnaires, ...response.questionnaires]);
        if (offset >= response.totalCount) break;
      }
    })();
  }, []);

  return (
    <Box sx={{ maxWidth: 500, display: 'flex' }}>
      <Typography variant="h5" sx={{ marginRight: '1em' }}>
        対象フォーム
      </Typography>
      <FormControl sx={{ maxWidth: 300 }} fullWidth>
        <InputLabel>フォーム名</InputLabel>
        <Select
          value={props.questionnaireId}
          label="フォーム名"
          onChange={props.onChange}
        >
          {questionnaires.map((questionnaire) => (
            <MenuItem value={String(questionnaire.id)} key={questionnaire.id}>
              {questionnaire.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TargetFormSelect;
