import { ChangeEvent } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import AddCircle from '@mui/icons-material/AddCircle';
import Cancel from '@mui/icons-material/Cancel';
import {
  EditingAnswerRange,
  QuestionType,
  RangeType
} from '../../interface/Question';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { useDispatch } from 'react-redux';
import {
  addAnswerRange,
  changeRangeType,
  changeRangeValue,
  removeAnswerRange
} from '../../redux/slice/QuestionnaireSlice';
import { TEXT_QUESTION_TYPES } from '../../common/questionType';
import { MAX_TEXT_LENGTH } from '../../common/answer';

type EditableAnswerRangesProps = {
  ranges?: EditingAnswerRange[];
  type: QuestionType;
};

const EditableAnswerRanges: React.FC<EditableAnswerRangesProps> = (props) => {
  const getRangeType = (range: EditingAnswerRange): RangeType => {
    if (range.maxValue === undefined && range.includesBoundaryValue)
      return '以上';
    else if (range.maxValue === undefined && !range.includesBoundaryValue)
      return 'より大きい';
    else if (range.minValue === undefined && range.includesBoundaryValue)
      return '以下';
    else if (range.minValue === undefined && !range.includesBoundaryValue)
      return 'より小さい';
    else if (range.includesBoundaryValue && !range.isEqual)
      return 'を含む範囲内';
    else if (range.includesBoundaryValue && range.isEqual) return 'と等しい';

    return 'の範囲内';
  };

  const rangeTypes: RangeType[] = [
    '以上',
    '以下',
    'より大きい',
    'より小さい',
    'を含む範囲内',
    'の範囲内',
    'と等しい'
  ];

  const dispatch = useDispatch();
  const onClickAdd = () => dispatch(addAnswerRange());
  const onClickRemove = (rangeIndex: number) => () =>
    dispatch(removeAnswerRange(rangeIndex));
  const onChangeRangeType =
    (rangeIndex: number) => (event: SelectChangeEvent) =>
      dispatch(
        changeRangeType({
          type: event.target.value as RangeType,
          index: rangeIndex
        })
      );
  const onChangeValue =
    (rangeIndex: number, isMin: boolean, isEqual: boolean) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      dispatch(
        changeRangeValue({
          index: rangeIndex,
          isMin,
          isEqual,
          value: event.target.value
        })
      );

  const isNumber = (str: string): boolean => str !== '' && !isNaN(Number(str));
  const existBothValue = (range: EditingAnswerRange): boolean =>
    range.minValue !== undefined && range.maxValue !== undefined;
  const isBothValueNumber = (range: EditingAnswerRange): boolean =>
    isNumber(range.minValue!) && isNumber(range.maxValue!);
  const isMaxGreaterThanMin = (range: EditingAnswerRange): boolean => {
    if (!isBothValueNumber) return true;
    return Number(range.minValue!) < Number(range.maxValue!);
  };

  const isTextLengthError = (
    range: EditingAnswerRange,
    forMin: boolean
  ): boolean => {
    if (!TEXT_QUESTION_TYPES.includes(props.type)) return false;

    if (forMin)
      return (
        range.minValue !== undefined && Number(range.minValue) > MAX_TEXT_LENGTH
      );

    return (
      range.maxValue !== undefined && Number(range.maxValue) > MAX_TEXT_LENGTH
    );
  };

  const generateHelperText = (
    range: EditingAnswerRange,
    forMin: boolean
  ): string => {
    if (range.minValue !== undefined && !isNumber(range.minValue) && forMin)
      return '最小値は数値である必要があります。';
    if (range.maxValue !== undefined && !isNumber(range.maxValue) && !forMin)
      return '最大値は数値である必要があります。';

    if (isTextLengthError(range, forMin))
      return `文字数の最${
        forMin ? '小' : '大'
      }値は${MAX_TEXT_LENGTH}以下である必要があります。`;

    return existBothValue(range) && !isMaxGreaterThanMin(range)
      ? `最${forMin ? '小' : '大'}値は最${forMin ? '大' : '小'}値より${
          forMin ? '小さい' : '大きい'
        }値である必要があります。`
      : '';
  };

  const generateEqualHelperText = (range: EditingAnswerRange): string => {
    if (range.minValue !== undefined && !isNumber(range.minValue))
      return 'この値は数値である必要があります。';

    if (isTextLengthError(range, true))
      return `この値は${MAX_TEXT_LENGTH}以下である必要があります。`;

    return '';
  };

  return (
    <>
      <Typography
        gutterBottom
        variant="body1"
        component="div"
        sx={{ marginTop: '0.5em' }}
      >
        回答の
        {props.type === 'number' ? '範囲' : '文字数'}
      </Typography>
      {props.ranges !== undefined && (
        <FormGroup sx={{ maxWidth: '75%' }} className="answer-range">
          {props.ranges.map((range: EditingAnswerRange, i: number) => {
            const type: RangeType = getRangeType(range);
            return (
              <Stack
                sx={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                spacing={2}
                direction="row"
                key={i}
              >
                <Typography>次の値</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <Select value={type} onChange={onChangeRangeType(i)}>
                    {rangeTypes.map((rangeType: RangeType) => (
                      <MenuItem value={rangeType} key={rangeType}>
                        {rangeType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography>:</Typography>
                {range.minValue !== undefined && !range.isEqual && (
                  <TextField
                    variant="standard"
                    value={range.minValue}
                    label="最小"
                    onChange={onChangeValue(i, true, false)}
                    error={
                      !isNumber(range.minValue) ||
                      (existBothValue(range) && !isMaxGreaterThanMin(range)) ||
                      isTextLengthError(range, true)
                    }
                    helperText={generateHelperText(range, true)}
                    className="min"
                  />
                )}
                {range.maxValue !== undefined && !range.isEqual && (
                  <TextField
                    variant="standard"
                    value={range.maxValue}
                    label="最大"
                    onChange={onChangeValue(i, false, false)}
                    error={
                      !isNumber(range.maxValue) ||
                      (existBothValue(range) && !isMaxGreaterThanMin(range)) ||
                      isTextLengthError(range, false)
                    }
                    helperText={generateHelperText(range, false)}
                    className="max"
                  />
                )}
                {range.isEqual && (
                  <TextField
                    variant="standard"
                    value={range.minValue}
                    label="値"
                    onChange={onChangeValue(i, true, true)}
                    error={
                      !isNumber(range.minValue!) ||
                      isTextLengthError(range, true)
                    }
                    helperText={generateEqualHelperText(range)}
                    className="equal"
                  />
                )}
                <ButtonWithToolTip
                  title="回答範囲を削除"
                  icon={<Cancel />}
                  onClick={onClickRemove(i)}
                />
              </Stack>
            );
          })}
        </FormGroup>
      )}
      <ButtonWithToolTip
        title="回答範囲の追加"
        icon={<AddCircle />}
        onClick={onClickAdd}
      />
    </>
  );
};

export default EditableAnswerRanges;
