import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Headline from '../common/Headline';
import { Notification } from '../../interface/Notification';
import { fetchNotifications } from '../../api';
import NotificationModal from './NotificationModal';
import NotificationTable from '../common/NotificationTable';

const TopNotifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const fetchedNotifications: Notification[] = await fetchNotifications(
        15,
        0
      );
      setNotifications(fetchedNotifications);
    })();
  }, []);

  const handleOpen = (isOpen: boolean) => () => setIsDetailOpen(isOpen);

  const getNotificationTitle = (id: number): string => {
    const targetNotification: Notification | undefined = notifications.find(
      (notification) => notification.id === targetId
    );

    if (targetNotification === undefined) return '';
    return targetNotification.title;
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Paper sx={{ width: '100%', mb: 2 }} variant="outlined" square>
        <Headline headline="お知らせ" />
        <NotificationTable
          notifications={notifications}
          maxHeight={250}
          onClickTableCell={(id: number) => () => {
            handleOpen(true)();
            setTargetId(id);
          }}
        />
      </Paper>
      <NotificationModal
        isOpen={isDetailOpen}
        id={targetId}
        title={getNotificationTitle(targetId)}
        handleClose={handleOpen(false)}
      />
    </Box>
  );
};

export default TopNotifications;
