import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Sorry: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={3}>
      <Stack alignItems="center" spacing={3}>
        <img src="/DoQ.ico" alt="Logo" width="100px" height="100px" />
        <Typography align="center">現在メンテナンス中です。 </Typography>
        <Typography align="center">
          メンテナンス終了後に再度アクセスをお願いします。
        </Typography>
      </Stack>
    </Box>
  );
};

export default Sorry;
