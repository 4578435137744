import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export const useAdminOnlyPage = (): [string, Promise<any>, Function] => {
  const [role, setRole] = useState('一般');
  const navigate = useNavigate();
  const user = Auth.currentAuthenticatedUser();

  const redirectFormCreatorAndGeneralUserToTop = async (
    user: Promise<any>,
    redirectCondition?: boolean
  ) => {
    const condition: boolean =
      redirectCondition === undefined ? false : redirectCondition;
    if ((await user).attributes['custom:role'] === '管理者' || condition) {
      setRole('管理者');
    } else {
      navigate('/');
    }
  };

  return [role, user, redirectFormCreatorAndGeneralUserToTop];
};
