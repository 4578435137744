import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { useDispatch } from 'react-redux';
import { switchOrder } from '../../redux/slice/QuestionnaireSlice';

type SwitchOrderButtonsProps = {
  index: number;
  isTop: boolean;
  isBottom: boolean;
  isDeleted: boolean;
  type: 'question' | 'message';
};

const SwitchOrderButtons: React.FC<SwitchOrderButtonsProps> = (props) => {
  const dispatch = useDispatch();
  const onClickSwitchOrder = (index: number, isUpward: boolean) => () =>
    dispatch(switchOrder({ index, isUpward }));

  return (
    <>
      <ButtonWithToolTip
        title="上へ"
        icon={<ArrowUpwardIcon />}
        onClick={onClickSwitchOrder(props.index, true)}
        disabled={props.isTop || props.isDeleted}
        className={`${props.type}-order-upward-button`}
      />
      <ButtonWithToolTip
        title="下へ"
        icon={<ArrowDownwardIcon />}
        onClick={onClickSwitchOrder(props.index, false)}
        disabled={props.isBottom || props.isDeleted}
        className={`${props.type}-order-downward-button`}
      />
    </>
  );
};

export default SwitchOrderButtons;
