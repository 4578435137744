import { useEffect, useState } from 'react';
import { listUsers } from '../../common/user';
import {
  User,
  ListUserResponse,
  UserPaginationToken
} from '../../interface/User';
import UserTable from './UserTable';
import Headline from '../common/Headline';
import UserFilterTextField from '../common/UserFilterTextField';

const UserManagementTopPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [paginationTokens, setPaginationTokens] = useState<UserPaginationToken>(
    { present: '' }
  );
  const [targetUserName, setTargetUserName] = useState('');

  useEffect(() => {
    (async () => {
      const response: ListUserResponse = await listUsers();
      setUsers(response.users);
      setPaginationTokens({
        present: '',
        next: response.paginationToken
      });
    })();
  }, []);

  return (
    <>
      <Headline headline="ユーザー管理" />
      <UserFilterTextField
        targetUserName={targetUserName}
        setUsers={setUsers}
        setTargetUserName={setTargetUserName}
        setPaginationTokens={setPaginationTokens}
      />
      <UserTable
        users={users}
        paginationTokens={paginationTokens}
        targetUserName={targetUserName}
        setUsers={setUsers}
        setPaginationTokens={setPaginationTokens}
      />
    </>
  );
};

export default UserManagementTopPage;
