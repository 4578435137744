import CardActions from '@mui/material/CardActions';
import FormGroup from '@mui/material/FormGroup';
import SwitchOrderButtons from './SwitchOrderButtons';
import EditButtons from './EditButtons';

type MessageCardActionProps = {
  index: number;
  isTop: boolean;
  isBottom: boolean;
  isDeleted: boolean;
  isEditing: boolean;
};

const MessageCardAction: React.FC<MessageCardActionProps> = (props) => {
  return (
    <CardActions
      sx={{ marginTop: '0.5em' }}
      disableSpacing
      aria-label="message-card-action"
    >
      <SwitchOrderButtons
        index={props.index}
        isTop={props.isTop}
        isBottom={props.isBottom}
        isDeleted={props.isDeleted}
        type="message"
      />
      <FormGroup sx={{ marginLeft: 'auto' }} row>
        <EditButtons
          index={props.index}
          isDeleted={props.isDeleted}
          isEditing={props.isEditing}
          type="message"
        />
      </FormGroup>
    </CardActions>
  );
};

export default MessageCardAction;
