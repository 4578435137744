import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import CardButton from '../common/CardButton';
import Headline from '../common/Headline';
import QuestionnairesTable from './QuestionnairesTable';

const FormManagementTopPage: React.FC = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState<string>('一般');
  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes['custom:role'] !== undefined) {
        setRole(user.attributes['custom:role']);
      }
    })();
  }, []);

  return (
    <>
      {
        <>
          <Headline headline="フォーム新規作成" />
          <CardButton
            className="form-create-card"
            headline="フォーム新規作成"
            description="新規フォームを作成します"
            onClick={() => {
              navigate('/form-management/new');
            }}
          />
        </>
      }
      <Headline headline="作成済みフォーム" />
      <QuestionnairesTable />
    </>
  );
};

export default FormManagementTopPage;
