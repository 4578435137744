import Markdown from 'react-markdown';

type QuestionnaireMessageProps = {
  questionId: number;
  message: string;
  disabled: boolean;
};

const QuestionnaireMessage: React.FC<QuestionnaireMessageProps> = (props) => {
  const questionId: number = props.questionId;
  const message: string = props.message;
  if (props.disabled) return <></>;

  return (
    <Markdown className="message" key={questionId}>
      {message}
    </Markdown>
  );
};

export default QuestionnaireMessage;
