import { useState, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { User } from '../../interface/User';
import { Auth } from 'aws-amplify';

type UserInformationProps = {
  user: User;
  role: string;
  setRole: Dispatch<SetStateAction<string>>;
};

const USER_ROLES = ['一般', '管理者'];

const UserInformation: React.FC<UserInformationProps> = (props) => {
  const [signingInUserRole, setSigningInUserRole] = useState<string>('一般');

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes['custom:role'] !== undefined) {
        setSigningInUserRole(user.attributes['custom:role']);
      }
    })();
  }, []);
  return (
    <>
      <Typography>ユーザー名: {props.user.name}</Typography>
      <Typography>メールアドレス: {props.user.email}</Typography>
      <Stack spacing={2} direction="row">
        <Typography sx={{ marginTop: '1em' }}>
          アカウント種別:{signingInUserRole === '管理者' ? '' : props.user.role}
        </Typography>
        {signingInUserRole === '管理者' ? (
          <Select
            className="select-user-role"
            value={props.role}
            onChange={(event: SelectChangeEvent) =>
              props.setRole(event.target.value)
            }
          >
            {USER_ROLES.map((userRole: string) => (
              <MenuItem value={userRole} key={userRole}>
                {userRole}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
};

export default UserInformation;
