import dayjs, { Dayjs } from 'dayjs';

export const convertDayjsToString = (timestamp: Dayjs): string => {
  const date: Date = timestamp.toDate();
  const padZero = (num: number): string => `${('0' + num).slice(-2)}`;

  return `${date.getFullYear()}/${padZero(date.getMonth() + 1)}/${padZero(
    date.getDate()
  )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(
    date.getSeconds()
  )}`;
};

export const convertStringToDayjs = (timestampString: string): Dayjs =>
  dayjs(timestampString.replaceAll('/', '-').replace(' ', 'T'));
