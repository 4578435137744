import { AlertColor } from '@mui/material';
import { useState } from 'react';

export const useSnackbar = (
  defaultMessage: string
): [boolean, AlertColor, string, Function, Function] => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [snackbarMessage, setSnackbarMessage] =
    useState<string>(defaultMessage);

  const openSnackbar = (newSeverity: AlertColor, message: string) => {
    setSeverity(newSeverity);
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const closeSnackbar = () => setIsSnackbarOpen(false);

  return [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ];
};
