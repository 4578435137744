const REGION = 'ap-northeast-1';

const awsConfig = {
  Auth: {
    region: REGION,
    userPoolId: (window as any)._env_?.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: (window as any)._env_
      ?.REACT_APP_AWS_USER_POOLS_CLIENT_ID
  },
  oauth: {
    domain: (window as any)._env_?.REACT_APP_AWS_USER_POOLS_COGNITO_DOMAIN,
    responseType: 'code',
    redirectSignIn: (window as any)._env_?.REACT_APP_AWS_REDIRECT_SIGN_IN,
    redirectSignOut: (window as any)._env_?.REACT_APP_AWS_REDIRECT_SIGN_OUT
  },
  aws_user_pools_web_client_id: (window as any)._env_
    ?.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  apiGatewayId: (window as any)._env_?.REACT_APP_API_GATEWAY_ID,
  cookieStorage: {
    secure: true
  }
};
export default awsConfig;
