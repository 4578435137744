import { ChangeEvent, KeyboardEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ButtonWithToolTip from '../common/ButtonWithToolTip';

type ItemAdditionTextFieldProps = {
  handleAddQuestionItem: (itemNames: string[]) => void;
};

const ItemAdditionTextField: React.FC<ItemAdditionTextFieldProps> = (props) => {
  const { handleAddQuestionItem } = props;

  const [newItemName, setNewItemName] = useState<string>('');

  return (
    <TextField
      variant="standard"
      InputProps={{
        startAdornment: (
          <ButtonWithToolTip
            className="add-question-item-button"
            title="追加"
            icon={<AddIcon />}
            onClick={() => {
              handleAddQuestionItem([newItemName]);
              setNewItemName('');
            }}
            disabled={newItemName === ''}
          />
        )
      }}
      value={newItemName}
      sx={{ marginTop: '0.5em', marginBottom: '0.5em' }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setNewItemName(event.target.value as string);
      }}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && newItemName !== '') {
          handleAddQuestionItem([newItemName]);
          setNewItemName('');
        }
      }}
      className="add-item-text-field"
    />
  );
};

export default ItemAdditionTextField;
