import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InformationTag from './InformationTag';
import { BASE_COLOR, ERROR_COLOR } from '../../common/color';
import { EditingQuestionItem } from '../../interface/Question';

type UneditableQuestionItemProps = {
  item: EditingQuestionItem;
  onClick: () => void;
};

const UneditableQuestionItem: React.FC<UneditableQuestionItemProps> = (
  props
) => {
  const { item, onClick } = props;

  const isDeleted: boolean = 'isDeleted' in item && item.isDeleted;

  return (
    <Box
      sx={{
        '> p': { display: 'inline-block', marginRight: '1em' },
        cursor: 'pointer'
      }}
      onClick={onClick}
      className="plaintext-item"
    >
      <Typography sx={{ color: item.name === '' ? ERROR_COLOR : undefined }}>
        {`${item.name === '' ? '（選択肢名未入力）' : item.name}${
          isDeleted ? '(削除済み)' : ''
        }`}
      </Typography>
      {item.isDescription && (
        <InformationTag content="記述式" width={5} color={BASE_COLOR} />
      )}
    </Box>
  );
};

export default UneditableQuestionItem;
