import {
  ExistingQuestion,
  EditingQuestionItem,
  ExistingQuestionItem,
  QuestionType
} from '../interface/Question';
import { HeaderForFormat } from '../interface/CSV';
import { Answer } from '../interface/Answer';
import { isNumber } from './index';

export const createHeadersForFormat = (
  headerRow: string,
  questions: ExistingQuestion[]
): HeaderForFormat[] => {
  const csvHeaders: string[] = parseCSV(headerRow);
  const headers: string[] = csvHeaders.filter(
    filterAnswerHeaders(csvHeaders.length)
  );
  return headers.map((header: string) => {
    // 選択式の質問の見出し
    const headline: string = header.includes('_')
      ? header.split('_')[0]
      : header;
    const target: ExistingQuestion | undefined = questions.find(
      (question: ExistingQuestion) =>
        question.headline === headline.replace(/\[Q\d+\] /g, '')
    );
    // CSVファイルにある質問の見出しが対象フォームに存在しない場合
    if (target === undefined)
      throw new Error(
        'アンケートの形式とCSVファイルの形式が一致しませんでした。'
      );

    const id: number = target.id;
    const type: QuestionType = target.type;
    const items: EditingQuestionItem[] | undefined = target.items;
    const isDescription: boolean =
      items?.findIndex(
        (item: EditingQuestionItem) => item.isDescription === true
      ) === -1
        ? false
        : true;

    // 質問が選択式の場合
    if (header.includes('_')) {
      // 質問の選択肢
      const item: EditingQuestionItem | undefined = target.items!.find(
        (item: EditingQuestionItem) => item.name === header.split('_')[1]
      );
      if (item === undefined)
        throw new Error(
          'アンケートの形式とCSVファイルの形式が一致しませんでした。'
        );
      const itemId: number = (item as ExistingQuestionItem).id;
      const isDescription: boolean = (item as ExistingQuestionItem)
        .isDescription;

      return { id, header, type, itemId, isDescription };
    }

    return { id, header, type, isDescription };
  });
};

// ヘッダーに存在するID,回答者,回答日,更新者,更新日を除外する
const filterAnswerHeaders =
  (length: number) =>
  (_: any, index: number): boolean =>
    index > 0 && index < length - 4;

// 最初と末尾の"を削除
const eliminateDoubleQuotation = (str: string): string => {
  if (str.match(/^"/g) && str.match(/"$/g)) return str.slice(1, -1);
  return str;
};

// 先頭に"があるか判定する
const startsWithOddDoubleQuotation = (str: string): boolean => {
  const chars: string[] = str.split('');

  const firstNotDoubleQuotationIndex: number = chars.findIndex(
    (char: string) => char !== '"'
  );
  return firstNotDoubleQuotationIndex % 2 === 1;
};

// startsWithOddDoubleQuotationを使用して末尾に"があるか判定するために文字列を逆転させる
const endsWithOddDoubleQuotation = (str: string): boolean => {
  const reversed: string = str.split('').reverse().join();

  return startsWithOddDoubleQuotation(reversed);
};

const decodeDoubleQuotation = (str: string) => str.replace(/""/g, '"');

export const parseCSV = (row: string): string[] => {
  return row
    .split(',')
    .map(eliminateDoubleQuotation)
    .reduce((accumulator: string[], str: string, index: number) => {
      // 質問または回答に,が使われていた場合の処理
      if (endsWithOddDoubleQuotation(str)) {
        return [
          ...accumulator.slice(0, -2),
          `${accumulator[accumulator.length - 2]},${str}`
        ];
      }
      // ""のみだった場合の処理
      if (accumulator.length > index) {
        return [
          ...accumulator.slice(0, -2),
          `${accumulator[accumulator.length - 2]},${str}`,
          ''
        ];
      }
      // 質問または回答に,が使われていた場合の処理
      if (startsWithOddDoubleQuotation(str)) {
        return [...accumulator, str, ''];
      }
      return [...accumulator, str];
    }, [])
    .map(eliminateDoubleQuotation)
    .map(decodeDoubleQuotation);
};

const getItemId = (
  questions: ExistingQuestion[],
  questionId: number,
  answer: string,
  rowIndex: number
): number => {
  const targetQuestion: ExistingQuestion = questions.find(
    (question: ExistingQuestion) => question.id === questionId
  )!;
  const targetQuestionItem: ExistingQuestionItem | undefined =
    targetQuestion.items!.find(
      (item: EditingQuestionItem) => item.name === answer
    ) as ExistingQuestionItem;

  if (targetQuestionItem === undefined) {
    throw new Error(
      `${rowIndex + 1}行目のデータの「${
        targetQuestion.headline
      }」に存在しない選択肢「${answer}」が指定されました。`
    );
  }
  return targetQuestionItem.id;
};

export const formatAnswer =
  (
    csvHeaders: HeaderForFormat[],
    questions: ExistingQuestion[],
    rowIndex: number
  ) =>
  (accumulator: Answer[], answer: string, index: number) => {
    const header: HeaderForFormat = csvHeaders[index];
    const questionId: number = header.id;
    if (header.type === 'radio' && header.isDescription && answer !== '') {
      const targetQuestion: ExistingQuestion = questions.find(
        (question: ExistingQuestion) => question.id === questionId
      )!;
      const isDescriptionAnswer: boolean = !Boolean(
        targetQuestion.items!.find(
          (item: EditingQuestionItem) => item.name === answer
        )
      );

      return [
        ...accumulator,
        {
          questionId,
          itemId: isDescriptionAnswer
            ? targetQuestion.items!.find(
                (item: EditingQuestionItem) => item.isDescription
              )!.id
            : getItemId(questions, questionId, answer, rowIndex),
          textAnswer: isDescriptionAnswer ? answer : undefined
        }
      ];
    } else if (
      (header.type === 'select' || header.type === 'radio') &&
      answer !== ''
    ) {
      const itemId: number = getItemId(questions, questionId, answer, rowIndex);
      return [...accumulator, { questionId, itemId }];
    } else if (
      header.type === 'check' &&
      !header.isDescription &&
      answer === '1'
    ) {
      return [...accumulator, { questionId, itemId: header.itemId! }];
    } else if (
      header.type === 'check' &&
      header.isDescription &&
      answer !== ''
    ) {
      return [
        ...accumulator,
        {
          questionId,
          itemId: header.itemId!,
          textAnswer: answer
        }
      ];
    } else if (
      ((header.type === 'text' || header.type === 'longtext') &&
        answer !== '') ||
      (header.type === 'number' && isNumber(answer))
    ) {
      return [...accumulator, { questionId, textAnswer: answer }];
    }

    return [...accumulator];
  };
