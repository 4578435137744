import Stack from '@mui/material/Stack';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { UserPaginationToken } from '../../interface/User';

type UserPaginationButtonsProps = {
  handlePrev: React.MouseEventHandler<HTMLButtonElement>;
  handleNext: React.MouseEventHandler<HTMLButtonElement>;
  paginationTokens: UserPaginationToken;
};

const UserPaginationButtons: React.FC<UserPaginationButtonsProps> = (props) => {
  return (
    <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
      <ButtonWithToolTip
        icon={<NavigateBeforeIcon />}
        title="前へ"
        onClick={props.handlePrev}
        disabled={props.paginationTokens.prev === undefined}
      />
      <ButtonWithToolTip
        icon={<NavigateNextIcon />}
        title="次へ"
        onClick={props.handleNext}
        disabled={props.paginationTokens.next === undefined}
      />
    </Stack>
  );
};

export default UserPaginationButtons;
