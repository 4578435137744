import { Dispatch, SetStateAction, useState } from 'react';
import Button from '@mui/material/Button';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import ReferenceToAnswerModal from './ReferenceToAnswerModal';
import { useDispatch } from 'react-redux';
import { overwriteAnswers } from '../../redux/slice/AnswerSlice';
import { Answer } from '../../interface/Answer';
import { fetchAnswersByMetadataId } from '../../api';

type AnswerQuotationButtonProps = {
  isPublic: boolean;
  setDisabledKeyQuestionChange: Dispatch<SetStateAction<boolean>>;
};

const AnswerQuotationButton: React.FC<AnswerQuotationButtonProps> = (props) => {
  const { isPublic, setDisabledKeyQuestionChange } = props;

  const inheritance: Inheritance | undefined = useSelector(
    (state) => state.answer.inheritance
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const setAnswers = (answers: Answer[]) => dispatch(overwriteAnswers(answers));

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    inheritance !== undefined &&
    inheritance.questionId !== undefined &&
    !inheritance.isSameUser &&
    isPublic && (
      <>
        <Button
          className="answer-quotation-button"
          onClick={() => setIsModalOpen(true)}
        >
          回答を引用
        </Button>
        <ReferenceToAnswerModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          reflectAnswers={(metadataId: number) => async () => {
            handleCloseModal();
            const targetAnswers: Answer[] = await fetchAnswersByMetadataId(
              metadataId
            );
            setAnswers(
              targetAnswers.filter(
                (answer: Answer) =>
                  answer.questionId !== inheritance.questionId!
              )
            );
            setDisabledKeyQuestionChange(true);
          }}
          quit={handleCloseModal}
        />
      </>
    )
  );
};

export default AnswerQuotationButton;
