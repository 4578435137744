import { Dispatch, useState, SetStateAction } from 'react';
import FormAuthorities from './FormAuthorities';
import AuthorityManager from '../common/AuthorityManager';
import { User, UserPaginationToken } from '../../interface/User';
import UserPaginationButtons from '../common/UserPaginationButtons';

type FormAuthorityManagerProps = {
  users: User[];
  havingUsers: User[];
  setHavingUsers: Dispatch<SetStateAction<User[]>>;
  checkedNotHavingUserIds: string[];
  setCheckedNotHavingUserIds: Dispatch<SetStateAction<string[]>>;
  paginationTokens: UserPaginationToken;
};

const getTargetUser = (checkedIds: string[], users: User[]): User[] =>
  checkedIds.map(
    (id: string) => users.find((user: User) => user.email === id)!
  );

const FormAuthorityManager: React.FC<FormAuthorityManagerProps> = (props) => {
  const [checkedHavingUserIds, setCheckedHavingUserIds] = useState<string[]>(
    []
  );

  const eliminateAuthorities = () => {
    props.setHavingUsers(
      props.havingUsers.filter(
        (having: User) => !checkedHavingUserIds.includes(having.email)
      )
    );

    setCheckedHavingUserIds([]);
  };

  const grantAuthorities = () => {
    props.setHavingUsers([
      ...props.havingUsers,
      ...getTargetUser(props.checkedNotHavingUserIds, props.users)
    ]);

    props.setCheckedNotHavingUserIds([]);
  };

  return (
    <AuthorityManager
      havingAuthorities={
        <FormAuthorities
          users={props.havingUsers}
          checkedIds={checkedHavingUserIds}
          setCheckedIds={setCheckedHavingUserIds}
        />
      }
      notHavingAuthorities={
        <FormAuthorities
          users={props.users.filter(
            (user: User) =>
              props.havingUsers.findIndex(
                (having: User) => having.email === user.email
              ) === -1
          )}
          checkedIds={props.checkedNotHavingUserIds}
          setCheckedIds={props.setCheckedNotHavingUserIds}
        />
      }
      eliminateAuthorities={eliminateAuthorities}
      grantAuthorities={grantAuthorities}
      checkedHavingIds={checkedHavingUserIds}
      checkedNotHavingIds={props.checkedNotHavingUserIds}
      paginationButtons={
        <UserPaginationButtons
          handleNext={() => {}}
          handlePrev={() => {}}
          paginationTokens={props.paginationTokens}
        />
      }
    />
  );
};

export default FormAuthorityManager;
