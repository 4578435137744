import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import NotificationForm from './NotificationForm';
import { fetchNotificationDetail } from '../../api';
import { NotificationDetail } from '../../interface/Notification';
import { useLocation } from 'react-router-dom';
import { useAdminOnlyPage } from '../../hooks/useAdminOnlyPage';

const NotificationEditPage: React.FC = () => {
  const location = useLocation();
  const [initialNotification, setInitialNotification] =
    useState<NotificationDetail>({
      title: '',
      content: '',
      typeId: 1,
      date: ''
    });
  const notificationId: number = Number(location.pathname.split('/')[2]);
  const [role, user, redirectFormCreatorAndGeneralUserToTop] =
    useAdminOnlyPage();

  useEffect(() => {
    (async () => {
      await redirectFormCreatorAndGeneralUserToTop(user);

      if (notificationId < 1) return;
      const detail: NotificationDetail | undefined =
        await fetchNotificationDetail(notificationId);
      if (detail !== undefined) setInitialNotification(detail);
    })();
  }, [notificationId]);

  return role === '一般' ? (
    <></>
  ) : (
    <>
      <Typography variant="h4">お知らせ編集</Typography>
      <NotificationForm
        initial={initialNotification}
        tempPath={`temp-notification-${notificationId}`}
        id={notificationId}
      />
    </>
  );
};

export default NotificationEditPage;
