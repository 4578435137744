import {
  DraggableAttributes,
  DraggableSyntheticListeners
} from '@dnd-kit/core';
import QuestionGroupCard from './QuestionGroupCard';
import {
  EditingQuestion,
  GroupedEditingQuestion
} from '../../interface/Question';
import SortableAccordion from './SortableAccordion';
import { questionIndexesHaveDuplicateHeadline } from '../../common/manageQuestion';
import { useSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { manageIsOpen } from '../../redux/slice/QuestionnaireSlice';

type QuestionGroupAccordionProps = {
  groupedQuestion: GroupedEditingQuestion;
  groupIndex: number;
  questionIndexOffset: number;
  isBottom: boolean;
  groupedIndex: number;
  handlerProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
  editingId?: number;
};

const QuestionGroupAccordion: React.FC<QuestionGroupAccordionProps> = (
  props
) => {
  const {
    groupedQuestion,
    editingId,
    groupIndex,
    groupedIndex,
    questionIndexOffset,
    isBottom,
    handlerProps
  } = props;

  const dispatch = useDispatch();

  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const switchIsOpen = () =>
    dispatch(manageIsOpen({ id: groupedQuestion.groupId, isQuestion: false }));

  const getGroupErrorMessage = (): string | undefined => {
    if (
      groupedQuestion.groupName === '' ||
      groupedQuestion.questions.length === 0
    ) {
      return 'グループに未入力項目があります';
    } else if (
      groupedQuestion.questions.find((question) =>
        question.id === editingId
          ? questionIndexesHaveDuplicateHeadline(
              editingQuestion,
              validationTarget
            )
          : questionIndexesHaveDuplicateHeadline(question, validationTarget)
      )
    ) {
      return 'グループ内の質問にエラーがあります';
    } else {
      return undefined;
    }
  };

  return (
    <SortableAccordion
      headline={`グループ${groupIndex + 1}: ${groupedQuestion.groupName}`}
      content={
        <QuestionGroupCard
          groupedQuestion={groupedQuestion}
          questionIndexOffset={questionIndexOffset}
          isBottom={isBottom}
          groupedIndex={groupedIndex}
        />
      }
      sx={{
        marginTop: '0.5em'
      }}
      isOpen={groupedQuestion.isOpen}
      onChange={() => {
        switchIsOpen();
      }}
      handlerProps={handlerProps}
      groupError={getGroupErrorMessage()}
    />
  );
};

export default QuestionGroupAccordion;
