import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { isNumber } from '../../common';
import { useDispatch } from 'react-redux';
import {
  addAnswer,
  removeAnswer,
  updateDescriptionAnswer
} from '../../redux/slice/AnswerSlice';
import { AnswerRange } from '../../interface/Question';
import {
  isAnswerRangeError,
  generateAnswerRangeErrorMessage,
  getTextAnswer
} from '../../common/answer';
import { Answer } from '../../interface/Answer';

type NumberFormProps = {
  questionId: number;
  disabled: boolean;
  required?: boolean;
  ranges?: AnswerRange[];
  answers: Answer[];
};

const NumberForm: React.FC<NumberFormProps> = (props) => {
  const required: boolean =
    props.required === undefined ? false : props.required;
  const answer: string = getTextAnswer(props.answers, props.questionId);

  const isRequireError: boolean = required && answer === '';

  const isError: boolean =
    isRequireError ||
    !isNumber(answer) ||
    isAnswerRangeError(Number(answer), props.ranges);

  const dispatch = useDispatch();
  const updateAnswer = (textAnswer: string) =>
    dispatch(
      updateDescriptionAnswer({ questionId: props.questionId, textAnswer })
    );
  const removeNumberAnswer = () =>
    dispatch(removeAnswer({ questionId: props.questionId }));
  const addNumberAnswer = (textAnswer: string) =>
    dispatch(addAnswer({ questionId: props.questionId, textAnswer }));

  const handleNumberChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (answer === undefined && event.target.value !== '') {
      addNumberAnswer(event.target.value);
    }
    if (event.target.value === '') {
      removeNumberAnswer();
      return;
    }
    updateAnswer(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="数字をこちらに入力してください。"
      value={answer}
      sx={{ marginTop: '0.5em', whiteSpace: 'pre-line' }}
      onChange={handleNumberChange}
      error={isError}
      helperText={
        props.disabled
          ? ''
          : `${isRequireError ? 'この質問は回答必須です。\n' : ''}${
              !isNumber(answer) ? '数字で回答を入力してください。\n' : ''
            }${generateAnswerRangeErrorMessage('number', props.ranges)}`
      }
      disabled={props.disabled}
    />
  );
};

export default NumberForm;
